import { Modal, Fade, Backdrop, IconButton, Checkbox, Container, Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '../icon/CheckIcon';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.75rem 2.5rem 2.875rem 2.5rem',
    width: '95%',
    maxWidth: '1500px',
    height: '95%',
    maxHeight: '50rem',
    overflow: 'hidden',
    background: `url(https://meta.sodportal.io/hp/images/modal/otherServices-cover.png) #fff`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundBlendMode: 'multiply',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('500')]: {
        padding: '1rem'
    }
}));

const ModalHead = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '& > h1': {
        fontSize: '2rem'
    }
}));

const ModalBody = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    [theme.breakpoints.down('700')]: {
        justifyContent: 'flex-end'
    }
}));

const ImageBox = styled('div')(({ theme }) => ({
    position: 'absolute',
    maxWidth: '34.5rem',
    minWidth: '20rem',
    width: '50%',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    '&.sakuraImage': {
        left: '0',
        bottom: '0'
    },
    '&.sodImage': { right: '0', top: '10%' },
    [theme.breakpoints.down('1560')]: {
        '&.sodImage': { left: '0', bottom: '0', transform: 'translate(-10%,20%)' },
        '&.sakuraImage': { left: '0', top: '0', transform: 'translate(30%,-10%)' }
    },
    [theme.breakpoints.down('962')]: {
        '&.sakuraImage': { transform: 'translate(0%,-20%)' }
    },
    [theme.breakpoints.down('700')]: {
        '&.sakuraImage': { transform: 'translate(-20%,-25%)' },
        '&.sodImage': { transform: 'translate(-10%,25%)' }
    }
}));

const ContentBox = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    zIndex: 2,
    '& > div svg': {
        fontSize: '1rem',
        color: '#1E88E5'
    },
    '& > div span': {
        paddingLeft: '0.75rem',
        fontSize: '1.25rem',
        color: alpha(theme.palette.text.primary, 0.68)
    },
    [theme.breakpoints.down('1560')]: {
        paddingLeft: '28rem'
    },
    [theme.breakpoints.down('962')]: {
        paddingLeft: '18rem'
    },
    [theme.breakpoints.down('700')]: {
        padding: '0.75rem',
        backgroundColor: 'rgba(255,255,255,0.86)',
        borderRadius: '0.5rem'
    }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    alignSelf: 'flex-end',
    '& > svg': { fontSize: '2.25rem', color: '#000' }
}));

const WebDevelopment = (props) => {
    const { open, onClose, className } = props;
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
            className={className}
        >
            <Fade in={open}>
                <Wrapper>
                    <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <ModalHead>
                            <StyledIconButton onClick={onClose}>
                                <CloseIcon />
                            </StyledIconButton>
                            <h1>{t('modal.webDevelopment.modalTitle')}</h1>
                        </ModalHead>
                        <ModalBody>
                            <ImageBox className="sakuraImage">
                                <img src="https://meta.sodportal.io/hp/images/modal/webDevelopment-item1.png" />
                            </ImageBox>
                            <ContentBox>
                                <div>
                                    <CheckIcon />
                                    <span>{t('modal.webDevelopment.contentsText1')}</span>
                                </div>
                                <div>
                                    <CheckIcon />
                                    <span>{t('modal.webDevelopment.contentsText2')}</span>
                                </div>
                                <div>
                                    <CheckIcon />
                                    <span>{t('modal.webDevelopment.contentsText3')}</span>
                                </div>
                                <div>
                                    <CheckIcon />
                                    <span>{t('modal.webDevelopment.contentsText4')}</span>
                                </div>
                                <div>
                                    <CheckIcon />
                                    <span>{t('modal.webDevelopment.contentsText5')}</span>
                                </div>
                                <div>
                                    <CheckIcon />
                                    <span>{t('modal.webDevelopment.contentsText6')}</span>
                                </div>
                            </ContentBox>
                            <ImageBox className="sodImage">
                                <img src="https://meta.sodportal.io/hp/images/modal/webDevelopment-item2.png" />
                            </ImageBox>
                        </ModalBody>
                    </Container>
                </Wrapper>
            </Fade>
        </Modal>
    );
};

export default WebDevelopment;
