import { Modal, Fade, Backdrop, IconButton, Checkbox, Box, Grid, Container } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '../icon/CheckIcon';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.75rem 2.5rem 2.875rem 2.5rem',
    width: '95%',
    maxWidth: '1500px',
    height: '95%',
    overflow: 'scroll',
    background: `url(https://meta.sodportal.io/hp/images/modal/otherServices-cover.png) #fff`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundBlendMode: 'multiply',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('922')]: {
        padding: '1rem'
    }
}));

const ModalHead = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    '& > h1': {
        fontSize: '2rem'
    }
}));

const ModalBody = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    [theme.breakpoints.down('1300')]: {
        flexDirection: 'column'
    }
}));

const SideArea = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% / 4)',
    '& > div:nth-of-type(1)': {
        width: '100%',
        aspectRatio: '1',
        background: 'url(https://meta.sodportal.io/hp/images/modal/otherServices-side1.png) rgb(30, 136, 229, 0.2)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        mixBlendMode: 'multiply',
        filter: 'grayscale(0.5)'
    },
    '& > div:nth-of-type(2)': {
        width: '100%',
        flex: 1,
        background: 'url(https://meta.sodportal.io/hp/images/modal/otherServices-side2.png) rgba(0,0,0,0.36)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        mixBlendMode: 'luminosity'
    },
    [theme.breakpoints.down('1300')]: {
        flexDirection: 'row',
        width: '100%',
        height: '12.5rem',
        '& > div:nth-of-type(1)': {
            width: '40%',
            backgroundPosition: 'top'
        }
    }
}));

const MainArea = styled('div')(({ theme }) => ({
    flex: 1
}));

const ItemBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.375rem',
    margin: '0 auto',
    padding: '1rem',
    height: '100%',
    maxWidth: '272px',
    border: '3px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: '3px',
    [theme.breakpoints.down('1300')]: {
        gap: '1.25rem'
    }
}));

const ItemImageBox = styled('div')(({ theme }) => ({
    width: '100%',
    height: '11.75rem',
    overflow: 'hidden',
    filter: 'drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.07))',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}));

const ItemContentBox = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    flex: 1,
    width: '100%',
    borderRadius: '1px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '20rem'
    }
}));

const TextBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '0.25rem',
    '& svg': {
        fontSize: '0.9375rem',
        color: 'rgba(0, 0, 0, 0.68)'
    }
}));

const TextTitle = styled('h3')(({ theme }) => ({
    textAlign: 'center',
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    whiteSpace: 'pre-wrap',
    '&.ja': {
        fontSize: '0.9375rem'
    }
}));

const TextPrimary = styled('span')(({ theme }) => ({
    fontSize: '0.9375rem',
    letterSpacing: '-0.02em',
    color: 'rgba(0, 0, 0, 0.48)'
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate(-50%,30%)',
    alignSelf: 'flex-end',
    '& > svg': { fontSize: '2.25rem', color: '#000' }
}));

const OtherServices = (props) => {
    const { open, onClose, className } = props;
    const { i18n, t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
            className={className}
        >
            <Fade in={open}>
                <Wrapper>
                    <Container maxWidth="lg">
                        <ModalHead>
                            <StyledIconButton onClick={onClose}>
                                <CloseIcon />
                            </StyledIconButton>
                            <h1>{t('modal.otherServices.modalTitle')}</h1>
                        </ModalHead>
                        <ModalBody>
                            <SideArea>
                                <div />
                                <div />
                            </SideArea>
                            <MainArea>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ItemBox>
                                            <ItemImageBox>
                                                <img src="https://meta.sodportal.io/hp/images/modal/otherServices-item1.png" />
                                            </ItemImageBox>
                                            <ItemContentBox>
                                                <TextTitle className={`${i18n.language}`}>
                                                    {t('modal.otherServices.contentsTitle1')}
                                                </TextTitle>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle1Text1')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle1Text2')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle1Text3')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle1Text4')}
                                                    </TextPrimary>
                                                </TextBox>
                                            </ItemContentBox>
                                        </ItemBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ItemBox>
                                            <ItemImageBox>
                                                <img src="https://meta.sodportal.io/hp/images/modal/otherServices-item2.png" />
                                            </ItemImageBox>
                                            <ItemContentBox>
                                                <TextTitle className={`${i18n.language}`}>
                                                    {t('modal.otherServices.contentsTitle2')}
                                                </TextTitle>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle2Text1')}
                                                    </TextPrimary>
                                                </TextBox>
                                            </ItemContentBox>
                                        </ItemBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ItemBox>
                                            <ItemImageBox>
                                                <img src="https://meta.sodportal.io/hp/images/modal/otherServices-item3.png" />
                                            </ItemImageBox>
                                            <ItemContentBox>
                                                <TextTitle className={`${i18n.language}`}>
                                                    {t('modal.otherServices.contentsTitle3')}
                                                </TextTitle>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle3Text1')}
                                                    </TextPrimary>
                                                </TextBox>
                                            </ItemContentBox>
                                        </ItemBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ItemBox>
                                            <ItemImageBox>
                                                <img src="https://meta.sodportal.io/hp/images/modal/otherServices-item4.png" />
                                            </ItemImageBox>
                                            <ItemContentBox>
                                                <TextTitle className={`${i18n.language}`}>
                                                    {t('modal.otherServices.contentsTitle4')}
                                                </TextTitle>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle4Text1')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle4Text2')}
                                                    </TextPrimary>
                                                </TextBox>
                                            </ItemContentBox>
                                        </ItemBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ItemBox>
                                            <ItemImageBox>
                                                <img src="https://meta.sodportal.io/hp/images/modal/otherServices-item5.png" />
                                            </ItemImageBox>
                                            <ItemContentBox>
                                                <TextTitle className={`${i18n.language}`}>
                                                    {t('modal.otherServices.contentsTitle5')}
                                                </TextTitle>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle5Text1')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle5Text2')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle5Text3')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle5Text4')}
                                                    </TextPrimary>
                                                </TextBox>
                                            </ItemContentBox>
                                        </ItemBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <ItemBox>
                                            <ItemImageBox>
                                                <img src="https://meta.sodportal.io/hp/images/modal/otherServices-item6.png" />
                                            </ItemImageBox>
                                            <ItemContentBox>
                                                <TextTitle className={`${i18n.language}`}>
                                                    {t('modal.otherServices.contentsTitle6')}
                                                </TextTitle>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle6Text1')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle6Text2')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle6Text3')}
                                                    </TextPrimary>
                                                </TextBox>
                                                <TextBox>
                                                    <CheckIcon />
                                                    <TextPrimary>
                                                        {t('modal.otherServices.contentsTitle6Text4')}
                                                    </TextPrimary>
                                                </TextBox>
                                            </ItemContentBox>
                                        </ItemBox>
                                    </Grid>
                                </Grid>
                            </MainArea>
                        </ModalBody>
                    </Container>
                </Wrapper>
            </Fade>
        </Modal>
    );
};

export default OtherServices;
