import { Box, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SplitTextIntoDivs from '../common/SplitTextIntoDivs';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { gsap } from 'gsap';

gsap.registerPlugin(MotionPathPlugin);
gsap.defaults({ ease: 'none' });

const About = () => {
    const { i18n, t } = useTranslation();
    const introRef = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap
                .timeline({
                    defaults: {
                        duration: 0.05,
                        autoAlpha: 1,
                        transformOrigin: 'center',
                        ease: 'back'
                    }
                })
                .to('.text01, .step0', { scale: 1.2 }, 0.2)
                .to('.text01, .step0', { duration: 0.25, scale: 1 }, 0.25)
                .to('.ball02', { scale: 1.5 }, 0.2)
                .to('.ball02', { scale: 1.2 }, 0.3)
                .to('.text02, .step1', { scale: 1.2 }, 0.4)
                .to('.text02, .step1', { duration: 0.25, scale: 1 }, 0.45)
                .to('.ball03', { scale: 1.5 }, 0.4)
                .to('.ball03', { scale: 1.2 }, 0.5)
                .to('.text03, .step2', { scale: 1.2 }, 0.6)
                .to('.text03, .step2', { duration: 0.25, scale: 1 }, 0.65)
                .to('.ball04', { scale: 1.5 }, 0.6)
                .to('.ball04', { scale: 1.2 }, 0.7)
                .to('.text04, .step3', { scale: 1.2 }, 0.8)
                .to('.text04, .step3', { duration: 0.25, scale: 1 }, 0.85)
                .to('.ball05', { scale: 1.5 }, 0.8)
                .to('.ball05', { scale: 1.2 }, 0.9);
            const main = gsap
                .timeline({
                    defaults: { duration: 1 },
                    scrollTrigger: {
                        trigger: '#svg',
                        scrub: true,
                        start: 'top center',
                        end: 'bottom center'
                    }
                })
                .to('.ball01', { duration: 0.01, autoAlpha: 1 })
                .fromTo('.theLine', { strokeDashoffset: 1000 }, { strokeDashoffset: 0 })
                .to(
                    '.ball01',
                    {
                        motionPath: {
                            path: '.theLine',
                            align: '.theLine',
                            alignOrigin: [0.5, 0.5]
                        }
                    },
                    '<'
                )
                .add(tl, 0);
        });

        return () => {
            ctx.revert();
        };
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                paused: true,
                scrollTrigger: {
                    trigger: '.intro-contents',
                    start: 'top bottom',
                    end: 'bottom top',
                    onEnter: () => tl.play()
                    // onEnterBack: () => tl.play(),
                    // onLeave: () => {
                    //     tl.reverse();
                    // },
                    // onLeaveBack: () => {
                    //     tl.reverse();
                    // }
                }
            });
            gsap.set('.intro-logo', { transformOrigin: '50% 50% 0' });
            tl.from('.intro-logo', {
                delay: 0.5,
                rotationY: -90,
                opacity: 0,
                duration: 1
            });
            tl.to('.intro-logo', {
                opacity: 1
            });
            tl.from(
                '.intro-text',
                {
                    delay: 0.5,
                    filter: 'blur(4px)',
                    yPercent: -100,
                    autoAlpha: 0,
                    stagger: 0.4,
                    ease: 'back'
                },
                0
            );
        }, introRef);

        return () => ctx.revert();
    }, []);

    const steps = useMemo(() => {
        const _steps = [
            {
                year: '2020',
                desc: (
                    <p>
                        <Trans
                            i18nKey="aboutSection.history2020"
                            components={{
                                b: <b />
                            }}
                        ></Trans>
                    </p>
                )
            },
            {
                year: '2021',
                desc: (
                    <p>
                        <Trans
                            i18nKey="aboutSection.history2021"
                            components={{
                                b: <b />
                            }}
                        ></Trans>
                    </p>
                )
            },
            {
                year: '2022',
                desc: (
                    <p>
                        <Trans
                            i18nKey="aboutSection.history2022"
                            components={{
                                b: <b />
                            }}
                        ></Trans>
                    </p>
                )
            },
            { year: '2023', desc: <p>{t('aboutSection.history2023')}</p> }
        ];
        return _steps;
    }, [i18n.language]);

    return (
        <Wrapper ref={introRef}>
            <Banner>
                <Typography
                    className={'mainTitle'}
                    variant="h1"
                    sx={{
                        letterSpacing: '0.1em',
                        textAlign: 'center',
                        maxWidth: '100%',
                        fontSize: { xs: '2.5rem', sm: '3.75rem' }
                    }}
                >
                    <SplitTextIntoDivs text={t('aboutSection.sectionTitle')} className="chars" />
                </Typography>
                <SubTitle className={'subTitle'} variant="subtitle1">
                    <SplitTextIntoDivs text={t('aboutSection.subTitle')} className="chars" />
                </SubTitle>
            </Banner>
            <IntroBox className="intro-contents">
                <LogoImageBox className="intro-logo">
                    <img src={'https://meta.sodportal.io/hp/images/logo-big-size.png'} />
                </LogoImageBox>

                <DescBox className="intro-desc">
                    <TextPrimary className="intro-text">
                        <Trans
                            i18nKey="aboutSection.contentTitle"
                            components={{
                                b: <b />
                            }}
                        ></Trans>
                    </TextPrimary>
                    <TextSecondary className="intro-text">
                        <Trans
                            i18nKey="aboutSection.contentSubTitle"
                            components={{
                                b: <b />
                            }}
                        ></Trans>
                    </TextSecondary>

                    <DescBoxParagraph className="intro-text">{t('aboutSection.contentText1')}</DescBoxParagraph>
                    <DescBoxParagraph className="intro-text">
                        <Trans
                            i18nKey="aboutSection.contentText2"
                            components={{
                                br: <br />
                            }}
                        ></Trans>
                    </DescBoxParagraph>
                    <DescBoxParagraph className="intro-text">
                        <strong>{t('aboutSection.contentText3')}</strong>
                    </DescBoxParagraph>
                </DescBox>
            </IntroBox>

            <Box
                sx={{
                    position: 'relative',
                    marginTop: '80px',
                    marginBottom: '280px',
                    marginLeft: '20px',
                    display: 'flex',
                    width: '100%',
                    height: '1000px'
                }}
            >
                <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 1000">
                    <path className="line01 line" d="M 0 200  600 200"></path>
                    <path className="line02 line" d="M 0 400  600 400"></path>
                    <path className="line03 line" d="M 0 600  600 600"></path>
                    <path className="line04 line" d="M 0 800  600 800"></path>
                    <text className="text01" x="30" y="190">
                        2020
                    </text>
                    <text className="text02" x="30" y="390">
                        2021
                    </text>
                    <text className="text03" x="30" y="590">
                        2022
                    </text>
                    <text className="text04" x="30" y="790">
                        2023
                    </text>

                    <path
                        className="theLine"
                        d="M1 0V1000"
                        fill="none"
                        stroke="#efefef"
                        strokeWidth="8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <circle className="ball ball01" r="10" cx="0" cy="100"></circle>
                    <circle className="ball ball02" r="10" cx="0" cy="201"></circle>
                    <circle className="ball ball03" r="10" cx="0" cy="401"></circle>
                    <circle className="ball ball04" r="10" cx="0" cy="601"></circle>
                    <circle className="ball ball05" r="10" cx="0" cy="801"></circle>
                </svg>
                {steps.map((step, index) => {
                    return (
                        <VerticalStepDescBox key={index} className={`step-box step${index}`}>
                            {step.desc}
                        </VerticalStepDescBox>
                    );
                })}
            </Box>
        </Wrapper>
    );
};

export default About;

const Wrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    'svg': {
        position: 'relative',
        flex: 1,
        overflow: 'visible',
        color: '#efefef',
        height: '1300px'
    },
    '.ball': {
        fill: '#efefef',
        visibility: 'hidden',
        '&.ball02': {
            fill: '#fff',
            strokeWidth: '0.25rem',
            stroke: '#607D8B'
        },
        '&.ball03': {
            fill: '#fff',
            strokeWidth: '0.25rem',
            stroke: '#607D8B'
        },
        '&.ball04': {
            fill: '#fff',
            strokeWidth: '0.25rem',
            stroke: '#1E88E5'
        },
        '&.ball05': {
            fill: '#fff',
            strokeWidth: '0.25rem',
            stroke: '#90CAF9'
        }
    },
    '.line': {
        fill: 'none',
        stroke: '#efefef',
        strokeWidth: '2px'
    },
    'text': {
        fontSize: '2rem',
        fontWeight: 'bold',
        visibility: 'hidden',
        '&.text01': { fill: '#607D8B' },
        '&.text02': { fill: '#607D8B' },
        '&.text03': { fill: '#1E88E5' },
        '&.text04': { fill: '#90CAF9' }
    },
    '.theLine': {
        strokeDasharray: 1000,
        strokeDashoffset: 1000
    }
}));

const Banner = styled('div')(({ theme }) => ({
    width: '100vw',
    paddingTop: '2.625rem',
    paddingBottom: '2.625rem',
    backgroundImage: 'url(https://meta.sodportal.io/hp/images/about-cover.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    letterSpacing: '0.1em',
    textAlign: 'center'
}));

const LogoImageBox = styled('div')(({ theme }) => ({
    height: '100%',
    '& > img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        [theme.breakpoints.down('lg')]: {
            maxHeight: '14rem'
        }
    }
}));

const DescBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
}));

const DescBoxParagraph = styled('p')(({ theme }) => ({
    fontsize: '0.9375rem',
    color: alpha(theme.palette.text.primary, 0.48),
    whiteSpace: 'pre-wrap',
    '& > strong': {
        paddingTop: '0.5rem'
    }
}));

const TextPrimary = styled('span')(({ theme }) => ({
    paddingBottom: '1rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    '& b': {
        color: theme.palette.primary.main
    }
}));

const TextSecondary = styled('p')(({ theme }) => ({
    fontSize: '1.25rem',
    fongWeight: 'bold',
    color: alpha(theme.palette.text.primary, 0.68),
    '& b': {
        color: theme.palette.primary.main
    },
    whiteSpace: 'pre-wrap'
}));

const IntroBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5rem',
    paddingTop: '3.75rem',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
    }
}));

const VerticalStepDescBox = styled('div')(({ theme }) => ({
    visibility: 'hidden',
    position: 'absolute',
    right: '20%',
    padding: '0.875rem',
    width: 'max-content',
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 0 1.25rem ${alpha(theme.palette.text.primary, 0.12)}`,
    whiteSpace: 'pre-wrap',
    '& p': { fontSize: '0.9375rem', color: alpha(theme.palette.text.primary, 0.52), textAlign: 'center' },
    '&.step0': { top: '22%' },
    '&.step1': { top: '48%' },
    '&.step2': {
        top: '73%',
        boxShadow: '0 0 1.25rem 0.0625rem rgba(30, 136, 229, 0.48)',
        border: `0.0625rem solid ${alpha(theme.palette.text.primary, 0.08)}`
    },
    '&.step3': {
        top: '100%',
        '& p': {
            fontSize: '2rem',
            fontWeight: 'bold',
            color: '#90CAF9'
        }
    },
    [theme.breakpoints.down('md')]: {
        right: '0%',
        left: '50%',
        '&.step2': { left: '28%' }
    },
    [theme.breakpoints.down(630)]: {
        left: '5%',
        '&.step0': { top: '31%' },
        '&.step1': { top: '57%' },
        '&.step2': { top: '83%', left: '-2.5%' },
        '&.step3': { top: '109%' }
    }
}));
