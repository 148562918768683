const SplitTextIntoDivs = (props) => {
    const { text, className } = props;
    const letters = text.split('').map((char, index) => {
        return (
            <div className={className} key={index}>
                {char === ' ' ? '\u00A0' : char}
            </div>
        );
    });
    return <span style={{ display: 'inline-flex' }}>{letters}</span>;
};

export default SplitTextIntoDivs;
