import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { styled, alpha } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import { useState } from 'react';

const HomeBanner = () => {
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        }
    };

    const [swiperIndex, setSwiperIndex] = useState(0);

    return (
        <SwiperWrap>
            <Swiper
                pagination={pagination}
                modules={[Pagination, Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                loop={true}
                onSlideChange={(e) => setSwiperIndex(e.realIndex || 0)}
            >
                <SwiperSlide>
                    <SlideItemBox>
                        <SlideItem1>
                            <Trans
                                i18nKey="mainSection.slide1Text1"
                                components={{
                                    1: <span />,
                                    2: <span />,
                                    3: <span />,
                                    b: <b />
                                }}
                            ></Trans>
                        </SlideItem1>
                        <video playsInline muted autoPlay loop>
                            <source src="https://meta.sodportal.io/hp/videos/video-1.mp4" type="video/mp4"></source>
                        </video>
                    </SlideItemBox>
                </SwiperSlide>
                <SwiperSlide>
                    <SlideItemBox>
                        <SlideItem2>
                            <SlideItemContentBox>
                                <SlideItemTitle>SOD PORTAL</SlideItemTitle>
                                <SlideItemSubtitle>NFT Decentralized Platform</SlideItemSubtitle>
                                <SlideItemWordBox>
                                    <div>
                                        <SlideItemWord>User Product Managment</SlideItemWord>
                                        <SlideItemWord>MetaMask connection</SlideItemWord>
                                    </div>
                                    <div>
                                        <SlideItemWord>Opensea connectivity</SlideItemWord>
                                        <SlideItemWord>NFT Minting Site</SlideItemWord>
                                    </div>
                                    <div>
                                        <SlideItemWord>UX/UI Design</SlideItemWord>
                                        <SlideItemWord>NFT DEX Platform</SlideItemWord>
                                    </div>
                                    <div>
                                        <SlideItemWord>PFP deisgn</SlideItemWord>
                                        <SlideItemWord>Mass Email</SlideItemWord>
                                    </div>
                                </SlideItemWordBox>
                            </SlideItemContentBox>
                            <div>
                                <img
                                    className="slideImage"
                                    src="https://meta.sodportal.io/hp/images/home-banner-item-2.png"
                                />
                            </div>
                        </SlideItem2>
                        <video playsInline muted autoPlay loop>
                            <source src="https://meta.sodportal.io/hp/videos/video-2.mp4" type="video/mp4"></source>
                        </video>
                    </SlideItemBox>
                </SwiperSlide>

                <SwiperSlide>
                    <SlideItemBox>
                        <SlideItem3>
                            <div>
                                <img
                                    className="slideImage"
                                    src="https://meta.sodportal.io/hp/images/home-banner-item-3.png"
                                />
                            </div>
                            <SlideItemContentBox>
                                <SlideItemTitle>SAKURA NFT</SlideItemTitle>
                                <SlideItemSubtitle>NFT Centralized Platform</SlideItemSubtitle>
                                <SlideItemWordBox>
                                    <SlideItemWord>User Product Managment</SlideItemWord>
                                    <SlideItemWord>Trade Functions (Drop, P2P, Auction)</SlideItemWord>
                                    <SlideItemWord>External Wallet Payment connection</SlideItemWord>
                                    <SlideItemWord>NFT Contract Minting / Mgmt.</SlideItemWord>
                                    <SlideItemWord>Platform BO Functions</SlideItemWord>
                                    <SlideItemWord>Wallet Mgmt.</SlideItemWord>
                                </SlideItemWordBox>
                            </SlideItemContentBox>
                        </SlideItem3>
                        <video playsInline muted autoPlay loop>
                            <source src="https://meta.sodportal.io/hp/videos/video-3.mp4" type="video/mp4"></source>
                        </video>
                    </SlideItemBox>
                </SwiperSlide>
            </Swiper>
        </SwiperWrap>
    );
};

export default HomeBanner;

const SwiperWrap = styled('div')(({ theme }) => ({
    '& .swiper': {
        width: '100%',
        height: '50rem',
        [theme.breakpoints.down('md')]: {
            height: '35rem'
        }
    },
    '& .swiper-wrapper': {
        willChange: 'transform'
    },
    '& .swiper-slide': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    '& .swiper-pagination': {
        bottom: '3.5rem'
    },
    '& .swiper-pagination-bullet': {
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '0',
        transition: 'width 0.5s ease'
    },
    '& .swiper-pagination-bullet-active': {
        width: '2.25rem',
        height: '0.75rem',
        backgroundColor: theme.palette.primary.main
    },
    '& .slideImage': {
        willChange: 'transform',
        transform: 'scale3d(1.5, 1.5, 1)',
        transition: 'transform 1s ease,opacity 1s ease'
    },
    '& .swiper-slide-active .slideImage': {
        transform: 'scale3d(1, 1, 1)',
        [theme.breakpoints.down('lg')]: {
            position: 'absolute',
            transform: 'scale3d(0.5, 0.5, 1) translate(50%, 50%)',
            top: '0',
            right: '0',
            opacity: '0.8',
            zIndex: '-1'
        },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            transform: 'scale3d(0.5, 0.5, 1) translate(50%, -5%)',
            top: '0',
            right: '0',
            opacity: '0.8',
            zIndex: '-1'
        }
    }
}));

const SlideItemBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& video': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }
}));

const SlideItem1 = styled('div')(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    '& span': {
        display: 'block',
        textAlign: 'center',
        fontSize: '3.25rem',
        fontWeight: 'bold',
        '& b': {
            color: theme.palette.primary.main,
            fontSize: '4rem'
        },
        '&:last-of-type': {
            fontSize: '1.75rem',
            fontWeight: 'normal',
            color: alpha(theme.palette.text.primary, 0.68)
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '95%',
            fontSize: '2.25rem',
            '& b': {
                fontSize: '3rem'
            },
            '&:last-of-type': {
                fontSize: '1rem'
            }
        }
    }
}));

const SlideItem2 = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1
}));

const SlideItem3 = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1
}));

const SlideItemTitle = styled('span')(({ theme }) => ({
    paddingBottom: '0.75rem',
    fontSize: '3.75rem',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    color: alpha(theme.palette.text.primary, 0.86),
    [theme.breakpoints.down('md')]: {
        fontSize: '2.75rem'
    }
}));

const SlideItemSubtitle = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '2.25rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: alpha(theme.palette.text.primary, 0.48),
    '&::before, &::after': {
        content: '""',
        position: 'relative',
        display: 'block',
        width: '1.5rem',
        height: '0.125rem',
        backgroundColor: alpha(theme.palette.text.primary, 0.12)
    }
}));

const SlideItemContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        alignItems: 'center'
    }
}));

const SlideItemWordBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    '& > div': {
        width: '100%',
        '& > span': {
            display: 'inline-block',
            width: '50%'
        }
    },
    [theme.breakpoints.down('md')]: {
        width: '120%',
        padding: '0.25rem',
        borderRadius: '0.25rem',
        backgroundColor: alpha(theme.palette.text.secondary, 0.68)
    }
}));

const SlideItemWord = styled('span')(({ theme }) => ({
    fontSize: '1.0625rem',
    color: alpha(theme.palette.text.primary, 0.32)
}));
