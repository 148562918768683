import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from './locales/en/common.json';
import ko from './locales/ko/common.json';
import ja from './locales/ja/common.json';

const browserLang = window.navigator.language.split('-')[0];
const defaultLang = localStorage.getItem('LANG') || browserLang || 'en';

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: {
        en: {
            common: en
        },
        ko: {
            common: ko
        },
        ja: {
            common: ja
        }
    },
    lng: defaultLang,
    fallbackLng: defaultLang,
    ns: ['common'],
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
