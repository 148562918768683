import { Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SplitTextIntoDivs from '../common/SplitTextIntoDivs';
import { gsap } from 'gsap';

const Team = () => {
    const { i18n, t } = useTranslation();
    const mainRef = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                paused: true,
                scrollTrigger: {
                    trigger: '.members-box',
                    start: 'top bottom',
                    end: 'bottom top',
                    onEnter: () => tl.play()
                    // onEnterBack: () => tl.play(),
                    // onLeave: () => {
                    //     tl.reverse();
                    // },
                    // onLeaveBack: () => {
                    //     tl.reverse();
                    // }
                }
            });

            tl.from('.members', {
                delay: 0.5,
                scale: 0,
                autoAlpha: 0,
                stagger: 0.2,
                ease: 'back'
            })
                .to('.members', {})
                .from(
                    '.member-name',
                    {
                        filter: 'blur(4px)',
                        xPercent: -100,
                        autoAlpha: 0,
                        stagger: 0.2,
                        ease: 'back'
                    },
                    1
                )
                .to('.member-name', {})
                .from(
                    '.member-exp',
                    {
                        filter: 'blur(4px)',
                        yPercent: -100,
                        autoAlpha: 0,
                        stagger: 0.1,
                        ease: 'power2.inOut'
                    },
                    1
                )
                .to('.member-exp', {});
        }, mainRef);

        return () => ctx.revert();
    }, []);

    const members = useMemo(() => {
        const _members = [
            {
                position: t('teamSection.item1Title'),
                name: t('teamSection.item1Name'),
                imageUrl: 'https://meta.sodportal.io/hp/images/members/ceo.png',
                desc: (
                    <Trans
                        i18nKey="teamSection.item1Text"
                        components={{
                            ul: <ul />,
                            li: <li />,
                            b: <b />
                        }}
                    ></Trans>
                )
            },
            {
                position: t('teamSection.item2Title'),
                name: t('teamSection.item2Name'),
                imageUrl: 'https://meta.sodportal.io/hp/images/members/coo.png',
                desc: (
                    <Trans
                        i18nKey="teamSection.item2Text"
                        components={{
                            ul: <ul />,
                            li: <li />,
                            b: <b />
                        }}
                    ></Trans>
                )
            },
            {
                position: t('teamSection.item3Title'),
                name: t('teamSection.item3Name'),
                imageUrl: 'https://meta.sodportal.io/hp/images/members/cmo.png',
                desc: (
                    <Trans
                        i18nKey="teamSection.item3Text"
                        components={{
                            ul: <ul />,
                            li: <li />,
                            b: <b />
                        }}
                    ></Trans>
                )
            },
            {
                position: (
                    <Trans
                        i18nKey="teamSection.item5Title"
                        components={{
                            1: <span className="smaller" />,
                            2: <span className="smaller" />
                        }}
                    ></Trans>
                ),
                name: t('teamSection.item5Name'),
                imageUrl: 'https://live.staticflickr.com/65535/52608148853_411349438a_o.png',
                desc: (
                    <Trans
                        i18nKey="teamSection.item5Text"
                        components={{
                            ul: <ul />,
                            li: <li />,
                            b: <b />
                        }}
                    ></Trans>
                )
            },
            {
                position: t('teamSection.item6Title'),
                name: t('teamSection.item6Name'),
                imageUrl: 'https://live.staticflickr.com/65535/52607654516_cc6268f2c0_o.png',
                desc: (
                    <Trans
                        i18nKey="teamSection.item6Text"
                        components={{
                            ul: <ul />,
                            li: <li />,
                            b: <b />
                        }}
                    ></Trans>
                )
            }
        ];
        return _members;
    }, [i18n.language]);

    return (
        <Wrapper ref={mainRef}>
            <Typography
                className={'mainTitle'}
                variant="h1"
                sx={{
                    letterSpacing: '0.1em',
                    textAlign: 'center',
                    maxWidth: '100%',
                    fontSize: { xs: '2.5rem', sm: '3.75rem' }
                }}
            >
                <SplitTextIntoDivs text={t('teamSection.sectionTitle')} className="chars" />
            </Typography>
            <SubTitle className={'subTitle'} variant="subtitle1">
                <SplitTextIntoDivs text={t('teamSection.subTitle')} className="chars" />
            </SubTitle>
            <MembersArea className="members-box">
                {members.map((member, index) => (
                    <MemberBox key={member.name} className="members">
                        <MemberINPBox>
                            <MemberImageBox className={index === 0 ? 'first' : ''}>
                                <img src={member.imageUrl} />
                            </MemberImageBox>
                            <NamePosBox className={`member-name ${index > 2 ? 'left' : ''}`}>
                                <MemberPosition>{member.position}</MemberPosition>
                                <MemberName>{member.name}</MemberName>
                            </NamePosBox>
                        </MemberINPBox>

                        <MemberDescription className={`member-exp ${index < 3 ? 'center' : ''}`}>
                            <Experience>{t('teamSection.itemTextTitle')}</Experience>
                            {member.desc}
                        </MemberDescription>
                    </MemberBox>
                ))}
            </MembersArea>
        </Wrapper>
    );
};

export default Team;

const Wrapper = styled('div')(({ theme }) => ({
    position: 'relative'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    letterSpacing: '0.1em',
    textAlign: 'center'
}));

const MembersArea = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: '5rem'
}));

const MemberBox = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3.75rem',
    maxWidth: '30rem',
    width: '50%',
    [theme.breakpoints.down('668')]: {
        alignItems: 'center',
        width: '100%',
        maxWidth: 'none'
    }
}));

const MemberPosition = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '185px',
    color: theme.palette.primary.main,
    fontSize: '1.625rem',
    fontWeight: 'bold',
    lineHeight: '1.25',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    '& .smaller:first-of-type': {
        fontSize: '1.4rem'
    },
    '& .smaller:last-of-type': {
        fontSize: '1.15rem'
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: '1.5rem',
        '& .smaller': {
            fontSize: '1.375rem'
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        '& .smaller': {
            fontSize: '1.125rem'
        }
    },
    [theme.breakpoints.down('sm')]: {
        left: '20%'
    }
}));

const MemberINPBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    alignItems: 'flex-end'
}));

const NamePosBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: '130px',
    [theme.breakpoints.down('668')]: {
        alignItems: 'flex-start'
    }
}));

const MemberImageBox = styled('div')(({ theme }) => ({
    height: '12.5rem',
    aspectRatio: '1',
    backgroundColor: '#D7D7D9',
    borderRadius: '50%',
    overflow: 'hidden',
    '&.first': {
        backgroundColor: '#DBD1FC'
    },
    '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        transform: 'translateY(0.25rem)'
    },
    [theme.breakpoints.down('lg')]: {
        height: '11rem'
    },
    [theme.breakpoints.down('md')]: {
        height: '10rem'
    }
}));

const MemberName = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'rgba(11, 12, 12, 0.86)',
    [theme.breakpoints.down('lg')]: {
        fontSize: '1.25rem'
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem'
    }
}));

const MemberDescription = styled('div')(({ theme }) => ({
    paddingTop: '1.25rem',
    paddingLeft: '3.5rem',
    minWidth: '80%',
    textAlign: 'center',
    color: alpha(theme.palette.text.primary, 0.48),
    fontSize: '0.9375rem',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '& ul': {
        listStyle: 'inside'
    },
    '& li': {
        textAlign: 'left'
    },
    [theme.breakpoints.down('668')]: {
        margin: '0 auto',
        paddingLeft: '0',
        minWidth: '250px'
    }
}));

const Experience = styled('span')(({ theme }) => ({
    display: 'block',
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    textAlign: 'left',
    color: alpha(theme.palette.text.primary, 0.68)
}));
