import { Modal, Fade, Backdrop, IconButton, Checkbox, Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DonutItem from './DonutItem';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.75rem 2.5rem 2.875rem 2.5rem',
    width: '95%',
    maxWidth: '1500px',
    height: '95%',
    overflow: 'scroll',
    background: `url(https://meta.sodportal.io/hp/images/modal/otherServices-cover.png) #fff`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundBlendMode: 'multiply',
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('922')]: {
        padding: '1rem'
    }
}));

const ModalHead = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: '1.5rem',
    '& > h1': {
        fontSize: '2rem',
        lineHeight: '1.2'
    }
}));

const ModalBody = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1
}));

const TabWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '1.25rem',
    paddingBottom: '3rem',
    [theme.breakpoints.down('825')]: {
        paddingBottom: '1rem'
    }
}));

const Tab = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    padding: '0 1.5rem 0.5rem 1.5rem',
    color: alpha(theme.palette.text.primary, 0.68),
    fontSize: '1.25rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&.isActive': {
        color: theme.palette.primary.main,
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0',
            bottom: '0',
            display: 'block',
            width: '100%',
            height: '0.25rem',
            backgroundColor: theme.palette.primary.main
        }
    }
}));

const ContentBox = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    '&.isActive': {
        display: 'flex'
    },
    [theme.breakpoints.down('825')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}));

const DonutBox = styled('div')(({ theme }) => ({
    position: 'relative',
    maxHeight: '450px',
    height: '100%',
    aspectRatio: '1',
    display: 'flex',
    flexWrap: 'wrap',
    width: 'fit-content',
    '& .centerImage': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '65%',
        transform: 'translate(-50%,-50%)',
        zIndex: 2
    },
    [theme.breakpoints.down('825')]: {
        height: '60%'
    }
}));

const DonutDescWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.75rem',
    '& > div:nth-of-type(3)': {
        order: 4
    },
    [theme.breakpoints.down('825')]: {
        width: '90%'
    }
}));

const DonutPiece = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: '0.25rem',
    width: '50%',
    height: '50%',
    '& svg': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    '& .topLeft': {
        color: '#A5C3F1'
    },
    '& .topRight': { color: '#F1C0A5', transform: 'rotate(90deg)' },
    '& .bottomLeft': { color: '#FBEFC3', transform: 'rotate(270deg)' },
    '& .bottomRight': { color: '#A5E3F1', transform: 'rotate(180deg)' }
}));

const DonutText = styled('p')(({ theme }) => ({
    position: 'absolute',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    zIndex: 2,
    '&.TextTopLeft': {
        top: '30%',
        left: '25%',
        '&.en': {
            left: '20%'
        }
    },
    '&.TextTopRight': {
        '&:nth-of-type(1)': { top: '10%', left: '10%' },
        '&:nth-of-type(2)': { top: '25%', left: '35%' },
        '&:nth-of-type(3)': { top: '55%', left: '68%' }
    },
    '&.TextBottomLeft': {
        '&:nth-of-type(1)': { top: '20%', left: '10%', '&.en': { left: '5%' } },
        '&:nth-of-type(2)': { top: '65%', left: '55%' }
    },
    '&.TextBottomRight': {
        '&:nth-of-type(1)': { top: '30%', left: '65%' },
        '&:nth-of-type(2)': { top: '70%', left: '25%' }
    }
}));

const DonutDesc = styled('div')(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    position: 'static',
    paddingBottom: '1.25rem',
    '&.TextTopLeft, &.TextBottomLeft': {
        '& > div': { order: '1' }
    },
    '& > span': { order: '2', fontSize: '0.9375rem', color: 'rgba(0, 0, 0, 0.48)', whiteSpace: 'pre-wrap' },
    '&.TextTopLeft': {
        top: '10%',
        left: '5%',
        '& > div': {
            order: '1',
            backgroundColor: '#A5C3F1'
        }
    },
    '&.TextTopRight': {
        '& > div': { backgroundColor: '#F1C0A5' },
        '&:nth-of-type(1)': { top: '10%', right: '5%' },
        '&:nth-of-type(2)': { top: '20%', right: '2%' }
    },
    '&.TextBottomLeft': {
        '& > div': { order: '1', backgroundColor: '#FBEFC3' },
        '&:nth-of-type(1)': { bottom: '20%', left: '5%' },
        '&:nth-of-type(2)': { bottom: '10%', left: '5%' }
    },
    '&.TextBottomRight': {
        '& > div': { backgroundColor: '#A5E3F1' },
        '&:nth-of-type(1)': { bottom: '20%', right: '1%' },
        '&:nth-of-type(2)': { bottom: '10%', right: '5%' }
    },
    [theme.breakpoints.down('825')]: {
        alignItems: 'flex-start'
    }
}));

const PointBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1px',
    '&::before': {
        content: '""',
        width: '0.5rem',
        height: '0.5rem',
        display: 'block',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.86)'
    }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    alignSelf: 'flex-end',
    '& > svg': { fontSize: '2.25rem', color: '#000' }
}));

const PlatformDevelopment = (props) => {
    const { open, onClose, className } = props;
    const { i18n, t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
            className={className}
        >
            <Fade in={open}>
                <Wrapper>
                    <ModalHead>
                        <StyledIconButton onClick={onClose}>
                            <CloseIcon />
                        </StyledIconButton>
                        <h1>{t('modal.platformDevelopment.modalTitle')}</h1>
                    </ModalHead>
                    <ModalBody>
                        <TabWrapper>
                            <Tab className={currentTab === 0 ? 'isActive' : ''} onClick={() => setCurrentTab(0)}>
                                {t('modal.platformDevelopment.tab1Title')}
                            </Tab>
                            <Tab className={currentTab === 1 ? 'isActive' : ''} onClick={() => setCurrentTab(1)}>
                                {t('modal.platformDevelopment.tab2Title')}
                            </Tab>
                        </TabWrapper>
                        <ContentBox className={currentTab === 0 ? 'isActive' : ''}>
                            <DonutBox>
                                <img
                                    className={'centerImage'}
                                    src="https://meta.sodportal.io/hp/images/modal/platformDevelopment-item1.png"
                                />
                                <DonutPiece>
                                    <DonutItem className="topLeft" />
                                    <DonutText className={`TextTopLeft ${i18n.language}`}>
                                        {t('modal.platformDevelopment.tab1Content1Title1')}
                                    </DonutText>
                                </DonutPiece>
                                <DonutPiece>
                                    <DonutItem className="topRight" />
                                    <DonutText className="TextTopRight">
                                        {t('modal.platformDevelopment.tab1Content2Title1')}
                                    </DonutText>
                                    <DonutText className="TextTopRight">
                                        {t('modal.platformDevelopment.tab1Content2Title2')}
                                    </DonutText>
                                    <DonutText className="TextTopRight">
                                        {t('modal.platformDevelopment.tab1Content2Title3')}
                                    </DonutText>
                                </DonutPiece>
                                <DonutPiece>
                                    <DonutItem className="bottomLeft" />
                                    <DonutText className={`TextBottomLeft`}>
                                        {t('modal.platformDevelopment.tab1Content3Title1')}
                                    </DonutText>
                                    <DonutText className="TextBottomLeft">
                                        {t('modal.platformDevelopment.tab1Content3Title2')}
                                    </DonutText>
                                </DonutPiece>
                                <DonutPiece>
                                    <DonutItem className="bottomRight" />
                                    <DonutText className="TextBottomRight">
                                        {t('modal.platformDevelopment.tab1Content4Title1')}
                                    </DonutText>
                                    <DonutText className="TextBottomRight">
                                        {t('modal.platformDevelopment.tab1Content4Title2')}
                                    </DonutText>
                                </DonutPiece>
                            </DonutBox>
                            <DonutDescWrap>
                                <div>
                                    <DonutDesc className="TextTopLeft">
                                        <span>{t('modal.platformDevelopment.tab1Content1Text1')}</span>
                                        <PointBox />
                                    </DonutDesc>
                                </div>
                                <div>
                                    <DonutDesc className="TextTopRight">
                                        <PointBox />
                                        <span>{t('modal.platformDevelopment.tab1Content2Text1')}</span>
                                    </DonutDesc>
                                    <DonutDesc className="TextTopRight">
                                        <PointBox />
                                        <span>{t('modal.platformDevelopment.tab1Content2Text2')}</span>
                                    </DonutDesc>
                                </div>
                                <div>
                                    <DonutDesc className="TextBottomLeft">
                                        <span> {t('modal.platformDevelopment.tab1Content3Text1')}</span>
                                        <PointBox />
                                    </DonutDesc>
                                    <DonutDesc className="TextBottomLeft">
                                        <span>{t('modal.platformDevelopment.tab1Content3Text2')}</span>
                                        <PointBox />
                                    </DonutDesc>
                                </div>
                                <div>
                                    <DonutDesc className="TextBottomRight">
                                        <PointBox /> <span>{t('modal.platformDevelopment.tab1Content4Text1')}</span>
                                    </DonutDesc>
                                    <DonutDesc className="TextBottomRight">
                                        <PointBox /> <span>{t('modal.platformDevelopment.tab1Content4Text2')}</span>
                                    </DonutDesc>
                                </div>
                            </DonutDescWrap>
                        </ContentBox>

                        <ContentBox className={currentTab === 1 ? 'isActive' : ''}>
                            <DonutBox>
                                <img
                                    className={'centerImage'}
                                    src="https://meta.sodportal.io/hp/images/modal/platformDevelopment-item2.png"
                                />
                                <DonutPiece>
                                    <DonutItem className="topLeft" />
                                    <DonutText className={`TextTopLeft ${i18n.language}`}>
                                        {t('modal.platformDevelopment.tab2Content1Title1')}
                                    </DonutText>
                                </DonutPiece>
                                <DonutPiece>
                                    <DonutItem className="topRight" />
                                    <DonutText className="TextTopRight">
                                        {t('modal.platformDevelopment.tab2Content2Title1')}
                                    </DonutText>
                                    <DonutText className="TextTopRight">
                                        {t('modal.platformDevelopment.tab2Content2Title2')}
                                    </DonutText>
                                    <DonutText className="TextTopRight">
                                        {t('modal.platformDevelopment.tab2Content2Title3')}
                                    </DonutText>
                                </DonutPiece>
                                <DonutPiece>
                                    <DonutItem className="bottomLeft" />
                                    <DonutText className={`TextBottomLeft ${i18n.language}`}>
                                        {t('modal.platformDevelopment.tab2Content3Title1')}
                                    </DonutText>
                                    <DonutText className="TextBottomLeft">
                                        {t('modal.platformDevelopment.tab2Content3Title2')}
                                    </DonutText>
                                </DonutPiece>
                                <DonutPiece>
                                    <DonutItem className="bottomRight" />
                                    <DonutText className="TextBottomRight">
                                        {t('modal.platformDevelopment.tab2Content4Title1')}
                                    </DonutText>
                                </DonutPiece>
                            </DonutBox>
                            <DonutDescWrap>
                                <div>
                                    <DonutDesc className="TextTopLeft">
                                        <span>{t('modal.platformDevelopment.tab2Content1Text1')}</span>
                                        <PointBox />
                                    </DonutDesc>
                                </div>
                                <div>
                                    <DonutDesc className="TextTopRight">
                                        <PointBox />
                                        <span>{t('modal.platformDevelopment.tab2Content2Text1')}</span>
                                    </DonutDesc>
                                    <DonutDesc className="TextTopRight">
                                        <PointBox />
                                        <span>{t('modal.platformDevelopment.tab2Content2Text2')}</span>
                                    </DonutDesc>
                                </div>
                                <div>
                                    <DonutDesc className="TextBottomLeft">
                                        <span>{t('modal.platformDevelopment.tab2Content3Text1')}</span>
                                        <PointBox />
                                    </DonutDesc>
                                    <DonutDesc className="TextBottomLeft">
                                        <span>{t('modal.platformDevelopment.tab2Content3Text2')}</span>
                                        <PointBox />
                                    </DonutDesc>
                                </div>
                                <div>
                                    <DonutDesc className="TextBottomRight">
                                        <PointBox />
                                        <span>{t('modal.platformDevelopment.tab2Content4Text1')}</span>
                                    </DonutDesc>
                                </div>
                            </DonutDescWrap>
                        </ContentBox>
                    </ModalBody>
                </Wrapper>
            </Fade>
        </Modal>
    );
};

export default PlatformDevelopment;
