import { Modal, Fade, Backdrop, IconButton, Checkbox } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1.75rem 2.5rem 2.875rem 2.5rem',
    maxWidth: '1500px',
    width: '95%',
    maxHeight: '50rem',
    height: '95%',
    overflow: 'hidden',
    background: `url(https://meta.sodportal.io/hp/images/modal/otherServices-cover.png) #fff`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundBlendMode: 'multiply',
    [theme.breakpoints.down('500')]: {
        padding: '1rem'
    }
}));

const ModalHead = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '& > h1': {
        fontSize: '2rem'
    }
}));

const ModalBody = styled('div')(({ theme }) => ({
    position: 'relative',
    maxWidth: '928px',
    height: '100%',
    margin: '0rem auto',
    top: '10%',
    [theme.breakpoints.down('md')]: {
        top: '15%'
    }
}));

const Circle = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: '0',
    top: '50%',
    backgroundColor: '#fff',
    transform: 'translateY(-50%)',
    borderRadius: '50%',
    boxShadow: '0px 0px 50px 1px rgba(30, 136, 229, 0.24)',
    opacity: '0',
    transition: 'opacity 1s ease',
    '&.isActive': {
        opacity: '1'
    },
    '&.step1': {
        width: '100%',
        aspectRatio: '1.2'
    },
    '&.step2': {
        left: '1rem',
        width: '77%',
        aspectRatio: '1.12'
    },
    '&.step3': {
        left: '1.5rem',
        width: '58%',
        aspectRatio: '1.08'
    },
    '&.step4': {
        left: '2rem',
        width: '34%',
        aspectRatio: '1',
        zIndex: 2
    },
    [theme.breakpoints.down('md')]: {
        '&.step1, &.step2, &.step3, &.step4': {
            aspectRatio: '1'
        },
        '&.step4': {
            top: '52%'
        }
    },
    [theme.breakpoints.down('sm')]: {
        '&.step1, &.step2, &.step3, &.step4': {
            aspectRatio: '0.8'
        },
        '&.step4.en': {
            top: '54%'
        }
    }
}));

const CircleInner = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '3rem',
    gap: '1rem',
    '&.step4': {
        alignItems: 'center'
    }
}));

const CircleTitle = styled('h2')(({ theme }) => ({
    position: 'absolute',
    top: '0.125rem',
    left: '50%',
    minWidth: '140px',
    transform: 'translateX(-50%)',
    whiteSpace: 'pre-wrap',
    color: 'rgba(30, 136, 229, 0.68)',
    fontSize: '1.5rem',
    lineHeight: '1.2',
    textAlign: 'center',
    '&.down': {
        top: '1.5rem'
    },
    '&.ja': {
        top: '1rem'
    },
    [theme.breakpoints.down('md')]: {
        top: '1rem',
        fontSize: '1.25rem'
    }
}));

const CircleSpan = styled('span')(({ theme }) => ({
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.68)',
    fontSize: '0.9375rem',
    textAlign: 'center',
    lineHeight: '1.2',
    transition: 'opacity 0.3s ease',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem'
    },
    [theme.breakpoints.down('500')]: {
        wordBreak: 'keep-all',
        maxWidth: '5rem'
    },
    '&.step1': {
        '&:nth-of-type(1)': {
            top: '15%',
            right: '20%'
        },
        '&:nth-of-type(2)': {
            top: '22%',
            right: '15%'
        },
        '&:nth-of-type(3)': {
            top: '29%',
            right: '10%'
        },
        '&:nth-of-type(4)': {
            top: '36%',
            right: '5%',
            '&.en': {
                right: '1%'
            }
        },
        [theme.breakpoints.down('md')]: {
            '&:nth-of-type(1)': {
                right: '18%'
            },
            '&:nth-of-type(2)': {
                right: '13%'
            },
            '&:nth-of-type(3)': {
                right: '8%'
            },
            '&:nth-of-type(4)': {
                right: '3%',
                '&.ja': {
                    maxWidth: '6rem'
                }
            }
        },
        [theme.breakpoints.down('500')]: {
            '&:nth-of-type(1)': {
                top: '20%',
                right: '10%',
                '&.en': {
                    top: '22%',
                    right: '5%'
                },
                '&.ja': {
                    top: '15%',
                    right: '17%'
                }
            },
            '&:nth-of-type(2)': {
                top: '27%',
                right: '3%',
                '&.en': {
                    top: '13%',
                    right: '13%'
                },
                '&.ja': {
                    top: '25%',
                    right: '11%'
                }
            },
            '&:nth-of-type(3)': {
                top: '38%',
                right: '3%',
                '&.en': {
                    top: '35%',
                    right: '0%'
                },
                '&.ja': {
                    top: '35%',
                    right: '6%'
                }
            },
            '&:nth-of-type(4)': {
                top: '46%',
                right: '0',
                '&.en': {
                    top: '48%',
                    right: '-1%'
                },
                '&.ja': {
                    right: '-3%',
                    wordBreak: 'break-word'
                }
            }
        }
    },
    '&.step2': {
        '&:nth-of-type(1)': {
            top: '15%',
            right: '20%'
        },
        '&:nth-of-type(2)': {
            top: '22%',
            right: '15%',
            '&.en': {
                marginTop: '3rem',
                right: '4%'
            }
        },
        [theme.breakpoints.down('500')]: {
            '&:nth-of-type(1)': {
                '&.en': {
                    top: '14%',
                    right: '10%'
                }
            }
        }
    },
    '&.step3': {
        '&:nth-of-type(1)': {
            top: '15%',
            right: '20%',
            '&.en': {
                right: '35%'
            }
        },
        '&:nth-of-type(2)': {
            top: '25%',
            right: '10%',
            '&.en': {
                top: '20%',
                right: '10%'
            }
        },
        '&:nth-of-type(3)': {
            top: '35%',
            right: '5%'
        },
        '&:nth-of-type(4)': {
            top: '45%',
            right: '10%'
        },
        '&:nth-of-type(5)': {
            top: '55%',
            right: '10%',
            '&.ja': {
                right: '4%'
            }
        },
        [theme.breakpoints.down('md')]: {
            '&:nth-of-type(1)': {
                right: '20%'
            },
            '&:nth-of-type(2)': {
                right: '10%'
            },
            '&:nth-of-type(3)': {
                right: '8%',
                '&.ja': {
                    top: '32%',
                    maxWidth: '6rem'
                }
            },
            '&:nth-of-type(4)': {
                right: '10%'
            },
            '&:nth-of-type(5)': {
                right: '3%',
                '&.en': {
                    top: '53%'
                },
                '&.ja': {
                    top: '52%',
                    maxWidth: '6rem'
                }
            }
        },
        [theme.breakpoints.down('500')]: {
            '&:nth-of-type(1)': {
                top: '15%',
                right: '12%',
                '&.ja': {
                    top: '18%',
                    right: '30%'
                }
            },
            '&:nth-of-type(2)': {
                top: '27%',
                right: '10%',
                '&.ja': {
                    top: '24%',
                    right: '8%'
                }
            },
            '&:nth-of-type(3)': {
                top: '39%',
                right: '4%',
                '&.en': {
                    marginTop: '-0.3rem'
                },
                '&.ja': {
                    top: '38%',
                    right: '1%',
                    wordBreak: 'break-word'
                }
            },
            '&:nth-of-type(4)': {
                top: '51%',
                right: '4%',
                '&.en': {
                    marginTop: '0.5rem',
                    whiteSpace: 'nowrap'
                },
                '&.ja': {
                    top: '52%',
                    right: '-10%'
                }
            },
            '&:nth-of-type(5)': {
                top: '60%',
                right: '4%',
                '&.en': {
                    top: '61%'
                },
                '&.ja': {
                    top: '60%',
                    right: '-4%'
                }
            }
        },
        '&.en': {
            fontSize: '0.75rem'
        }
    }
}));

const CircleSpanFinal = styled('span')(({ theme }) => ({
    position: 'relative',
    color: 'rgba(0, 0, 0, 0.68)',
    fontSize: '0.9375rem',
    textAlign: 'center',
    wordBreak: 'keep-all',
    minWidth: '100px',
    lineHeight: '1.2',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem',
        '&.en:nth-of-type(1)': {
            top: '4%',
            whiteSpace: 'noWrap'
        }
    },
    [theme.breakpoints.down('sm')]: {
        '&.en:nth-of-type(1)': {
            top: '20%'
        },
        '&.en:nth-of-type(2)': {
            top: '20%'
        }
    }
}));

const ArrowBox = styled('div')(({ theme, stack }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: '32%',
    left: stack > 3 ? '20%' : '-20%',
    opacity: stack > 3 ? 1 : 0,
    width: '85%',
    zIndex: 1,
    transition: 'left 1s linear, opacity 2s ease',
    '& > div:first-of-type': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
        paddingRight: '0.5rem',
        marginRight: '-1px',
        height: '3rem',
        background: 'linear-gradient(to left, rgba(30, 136, 229,1), rgba(30, 136, 229,0) 100%)',
        color: '#fff',
        '& > span': {
            fontSize: '1.5rem',
            fontWeight: 'bold'
        }
    },
    '& > div:last-of-type': {
        width: '0',
        height: '0',
        borderBottom: '3rem solid transparent',
        borderTop: '3rem solid transparent',
        borderLeft: '3rem solid #1E88E5',
        borderRight: '3rem solid transparent'
    },
    [theme.breakpoints.down('md')]: {
        bottom: '34%',
        width: '86%',
        '& > div:first-of-type > span': {
            fontSize: '1.25rem'
        }
    },
    [theme.breakpoints.down('692')]: {
        width: '90%',
        '& > div:first-of-type > span': {
            fontSize: '1rem'
        }
    },
    [theme.breakpoints.down('500')]: {
        bottom: '30%',
        '& > div:first-of-type': {
            fontSize: '0.9375rem'
        },
        '& > div:first-of-type': {
            lineHeight: '1rem',
            whiteSpace: 'pre-wrap'
        }
    },
    [theme.breakpoints.down('400')]: {
        bottom: '32%'
    }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    alignSelf: 'flex-end',
    '& > svg': { fontSize: '2.25rem', color: '#000' }
}));

const BusinessConsulting = (props) => {
    const { open, onClose, className } = props;
    const { i18n, t } = useTranslation();
    const [stack, setStack] = useState(1);

    useEffect(() => {
        const MainImage = () => {
            if (stack === 4) {
                return clearTimeout(timer);
            }
            setStack(stack + 1);
        };
        const timer = setTimeout(() => MainImage(), 500);
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
            className={className}
        >
            <Fade in={open}>
                <Wrapper>
                    <ModalHead>
                        <StyledIconButton onClick={onClose}>
                            <CloseIcon />
                        </StyledIconButton>
                        <h1>{t('modal.businessConsulting.modalTitle')}</h1>
                    </ModalHead>
                    <ModalBody>
                        <>
                            <Circle className={`step1 ${stack >= 1 && `isActive`}`}>
                                <CircleInner>
                                    <CircleTitle className={`${i18n.language}`}>
                                        {t('modal.businessConsulting.contentsTitle4')}
                                    </CircleTitle>
                                    <CircleSpan className={`step1 ${i18n.language}`}>
                                        {t('modal.businessConsulting.contentsTitle4Text1')}
                                    </CircleSpan>
                                    <CircleSpan className={`step1 ${i18n.language}`}>
                                        {t('modal.businessConsulting.contentsTitle4Text2')}
                                    </CircleSpan>
                                    <CircleSpan className={`step1 ${i18n.language}`}>
                                        {t('modal.businessConsulting.contentsTitle4Text3')}
                                    </CircleSpan>
                                    <CircleSpan className={`step1 ${i18n.language}`}>
                                        {t('modal.businessConsulting.contentsTitle4Text4')}
                                    </CircleSpan>
                                </CircleInner>
                            </Circle>
                            <ArrowBox stack={stack}>
                                <div>
                                    <span>{'Good-Block Business \nConsulting System'}</span>
                                </div>
                                <div></div>
                            </ArrowBox>
                        </>
                        <Circle className={`step2 ${stack >= 2 && `isActive`}`}>
                            <CircleInner>
                                <CircleTitle className="down">
                                    {t('modal.businessConsulting.contentsTitle3')}
                                </CircleTitle>
                                <CircleSpan className={`step2 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle3Text1')}
                                </CircleSpan>
                                <CircleSpan className={`step2 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle3Text2')}
                                </CircleSpan>
                            </CircleInner>
                        </Circle>
                        <Circle className={`step3 ${stack >= 3 && `isActive`}`}>
                            <CircleInner>
                                <CircleTitle className="down">
                                    {t('modal.businessConsulting.contentsTitle2')}
                                </CircleTitle>
                                <CircleSpan className={`step3 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle2Text1')}
                                </CircleSpan>
                                <CircleSpan className={`step3 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle2Text2')}
                                </CircleSpan>
                                <CircleSpan className={`step3 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle2Text3')}
                                </CircleSpan>
                                <CircleSpan className={`step3 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle2Text4')}
                                </CircleSpan>
                                <CircleSpan className={`step3 ${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle2Text5')}
                                </CircleSpan>
                            </CircleInner>
                        </Circle>
                        <Circle className={`step4 ${i18n.language} ${stack >= 4 && `isActive`}`}>
                            <CircleInner className={'step4'}>
                                <CircleTitle className="down">
                                    {t('modal.businessConsulting.contentsTitle1')}
                                </CircleTitle>
                                <CircleSpanFinal className={`${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle1Text1')}
                                </CircleSpanFinal>
                                <CircleSpanFinal className={`${i18n.language}`}>
                                    {t('modal.businessConsulting.contentsTitle1Text2')}
                                </CircleSpanFinal>
                            </CircleInner>
                        </Circle>
                    </ModalBody>
                </Wrapper>
            </Fade>
        </Modal>
    );
};

export default BusinessConsulting;
