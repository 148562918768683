import { Box, Fab, Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { styled, alpha } from '@mui/material/styles';
import Contact from '../modal/Contact';

const MenuWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    right: '2rem',
    bottom: '3.5rem',
    zIndex: 100,
    '& button': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '3rem',
        height: '3rem',
        backgroundColor: '#fff',
        borderRadius: 'unset',
        boxShadow: '0 0 1rem 0.0625rem rgba(0, 0, 0, 0.16)',
        transition: 'background-color 0.3s ease-in-out',
        '& > span': {
            fontSize: '0.5rem',
            fontWeight: 'bold',
            transition: 'color 0.1s ease-in-out'
        },
        '& svg': {
            fontSize: '1.5rem',
            transition: 'color 0.1s ease-in-out'
        },
        [theme.breakpoints.down('md')]: {
            width: '4rem',
            height: '4rem',
            '& svg': {
                fontSize: '1.25rem'
            }
        }
    },
    '& .email-button': {
        '& svg': {
            color: '#4EB3F7'
        },
        '& span': {
            color: '#4EB3F7'
        },
        '&:hover': {
            backgroundColor: '#4EB3F7',
            '& svg': {
                color: '#fff'
            },
            '& span': {
                color: '#fff'
            }
        }
    },
    '& .top-button': {
        '& svg': {
            color: 'rgba(0, 0, 0, 0.48)'
        },
        '& span': {
            color: 'rgba(0, 0, 0, 0.48)'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.48)',
            '& svg': {
                color: '#fff'
            },
            '& span': {
                color: '#fff'
            }
        }
    }
}));

const ScrollMenu = () => {
    const [isShow, setIsShow] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    /**
     * Paget To Top
     */
    const showIcon = () => {
        if (window.scrollY >= 150) {
            !isShow && setIsShow(true);
        } else {
            setIsShow(false);
        }
    };

    const showModal = () => {
        setViewModal(true);
    };

    const closeModal = () => {
        setViewModal(false);
    };

    useEffect(() => {
        // 스크롤바 이벤트 리스너 설정
        window.addEventListener('scroll', showIcon);
        return () => {
            window.removeEventListener('scroll', showIcon);
        };
    }, []);

    return (
        <MenuWrapper>
            <Box sx={{ display: isShow ? 'flex' : 'none', flexDirection: 'column', gap: '0.75rem' }}>
                <Fade in={isShow} timeout={1000}>
                    <Fab className="email-button" color="default" aria-label="add" size="small" onClick={showModal}>
                        <EmailOutlinedIcon />
                        <span>CONTACT</span>
                    </Fab>
                </Fade>
                <Fade in={isShow} timeout={1000}>
                    <Fab
                        className="top-button"
                        color="default"
                        aria-label="add"
                        size="small"
                        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                    >
                        <ArrowUpwardIcon />
                        <span>TOP</span>
                    </Fab>
                </Fade>
            </Box>
            {viewModal && <Contact open={viewModal} onClose={closeModal} />}
        </MenuWrapper>
    );
};

export default ScrollMenu;
