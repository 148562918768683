import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5rem 0'
}));

const Title = styled('h1')(({ theme }) => ({
    fontSize: '8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '-0.04em',
    color: '#19161'
}));

const SubTitle = styled('h2')(({ theme }) => ({
    paddingBottom: '2rem',
    fontSize: '4rem',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '-0.03em',
    color: '#19161'
}));

const NotFoundPage = () => {
    return (
        <Wrapper>
            <Title>404!</Title>
            <SubTitle>Page Not Found</SubTitle>
            <Link to="/">
                <Button
                    variant="contained"
                    endIcon={<LoginIcon />}
                    sx={{
                        '& .text': { fontSize: '1.5rem' },
                        '& svg': {
                            fontSize: '1.5rem'
                        }
                    }}
                >
                    <span className="text">GO HOME</span>
                </Button>
            </Link>
        </Wrapper>
    );
};

export default NotFoundPage;
