import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import { Box, IconButton, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useLayoutEffect, useRef, useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import SplitTextIntoDivs from '../common/SplitTextIntoDivs';
import { gsap } from 'gsap';

const TechStack = () => {
    const swiperRef = useRef(null);
    const { t } = useTranslation();
    const [swiperIndex, setSwiperIndex] = useState(0);
    const mainRef = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                delay: 0.25,
                paused: true,
                scrollTrigger: {
                    trigger: '.carousel-container',
                    start: 'top bottom',
                    end: 'center top',
                    onEnter: () => tl.play()
                    // onEnterBack: () => tl.play(),
                    // onLeave: () => {
                    //     tl.reverse();
                    // },
                    // onLeaveBack: () => {
                    //     tl.reverse();
                    // }
                }
            });
            tl.add([
                gsap.from('.prev', {
                    xPercent: 500,
                    filter: 'blur(4px)',
                    autoAlpha: 0,
                    duration: 0.4
                }),
                gsap.from('.next', {
                    xPercent: -500,
                    filter: 'blur(4px)',
                    autoAlpha: 0,
                    duration: 0.4
                }),
                gsap.from('.carousel-container', {
                    scale: 0,
                    yPercent: 100,
                    filter: 'blur(4px)',
                    autoAlpha: 0,
                    duration: 0.4
                })
            ]);

            tl.to('.carousel-container', {
                autoAlpha: 1,
                ease: 'power3.inOut'
            });
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, []);

    return (
        <Wrapper ref={mainRef}>
            <Typography
                className={'mainTitle'}
                variant="h1"
                sx={{
                    letterSpacing: '0.1em',
                    textAlign: 'center',
                    maxWidth: '100%',
                    fontSize: { xs: '2.5rem', sm: '3.75rem' }
                }}
            >
                <SplitTextIntoDivs text={t('techStackSection.sectionTitle')} className="chars" />
            </Typography>
            <SubTitle className={'subTitle'} variant="subtitle1">
                <SplitTextIntoDivs text={t('techStackSection.subTitle')} className="chars" />
            </SubTitle>
            <SwiperWrap>
                <StyledIconButton onClick={() => swiperRef.current?.slidePrev()} className="prev">
                    <NavigateBeforeIcon />
                </StyledIconButton>
                <StyledIconButton onClick={() => swiperRef.current?.slideNext()} className="next">
                    <NavigateNextIcon />
                </StyledIconButton>
                <Box
                    sx={{
                        position: 'absolute',
                        right: '0',
                        background: '#fff',
                        backdropFilter: 'blur(4px)',
                        mask: 'linear-gradient(90deg,transparent, black 80%)',
                        width: '6.25rem',
                        height: '100%',
                        zIndex: 999
                    }}
                />

                <Swiper
                    navigation={false}
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1.1}
                    spaceBetween={12}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    breakpoints={{ 1280: { slidesPerView: 3 }, 960: { slidesPerView: 2 }, 480: { slidesPerView: 1.5 } }}
                    onSlideChange={(e) => setSwiperIndex(e.realIndex || 0)}
                    className="carousel-container"
                >
                    <SwiperSlide>
                        <ItemActionBox>
                            <StackTitle>BlockChain</StackTitle>
                            <StackListBox>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-eth.png" />
                                    </div>
                                    <span>Ethereum</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-polygon.png" />
                                    </div>
                                    <span>Polygon</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-hardhat.png" />
                                    </div>
                                    <span>Hardhat</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-truffle.png" />
                                    </div>
                                    <span>Truffle</span>
                                </StackListItem>
                            </StackListBox>
                        </ItemActionBox>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ItemActionBox>
                            <StackTitle>Language</StackTitle>
                            <StackListBox>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-java.png" />
                                    </div>
                                    <span>Java</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-js.png" />
                                    </div>
                                    <span>Javascript</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-ts.png" />
                                    </div>
                                    <span>Typescript</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-solidity.png" />
                                    </div>
                                    <span>Solidity</span>
                                </StackListItem>
                            </StackListBox>
                        </ItemActionBox>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ItemActionBox>
                            <StackTitle>Front-end</StackTitle>
                            <StackListBox>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-reactjs.png" />
                                    </div>
                                    <span>ReactJS</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-nextjs.png" />
                                    </div>
                                    <span>NextJS</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-redux.png" />
                                    </div>
                                    <span>Redux</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-mui.png" />
                                    </div>
                                    <span>MUI</span>
                                </StackListItem>
                            </StackListBox>
                        </ItemActionBox>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ItemActionBox>
                            <StackTitle>Back-end</StackTitle>
                            <StackListBox>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-spring.png" />
                                    </div>
                                    <span>Spring</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-springboot.png" />
                                    </div>
                                    <span>SpringBoot</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-nodejs.png" />
                                    </div>
                                    <span>NodeJS</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-express.png" />
                                    </div>
                                    <span>ExpressJS</span>
                                </StackListItem>
                            </StackListBox>
                        </ItemActionBox>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ItemActionBox>
                            <StackTitle>Database</StackTitle>
                            <StackListBox>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-mariadb.png" />
                                    </div>
                                    <span>Maria DB</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-mysql.png" />
                                    </div>
                                    <span>MySQL</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-redis.png" />
                                    </div>
                                    <span>Redis</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-mongodb.png" />
                                    </div>
                                    <span>Mongodb</span>
                                </StackListItem>
                            </StackListBox>
                        </ItemActionBox>
                    </SwiperSlide>

                    <SwiperSlide>
                        <ItemActionBox>
                            <StackTitle>Design</StackTitle>
                            <StackListBox>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-figma.png" />
                                    </div>
                                    <span>Figma</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-ps.png" />
                                    </div>
                                    <span>Photoshop</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-xd.png" />
                                    </div>
                                    <span>XD</span>
                                </StackListItem>
                                <StackListItem>
                                    <div>
                                        <img src="https://meta.sodportal.io/hp/images/tech-stack/tech-stack-ai.png" />
                                    </div>
                                    <span>Illustration</span>
                                </StackListItem>
                            </StackListBox>
                        </ItemActionBox>
                    </SwiperSlide>
                </Swiper>
            </SwiperWrap>
        </Wrapper>
    );
};

export default TechStack;

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    letterSpacing: '0.1em',
    textAlign: 'center'
}));

const SwiperWrap = styled('div')(({ theme }) => ({
    maxWidth: 'calc(100% - 7.5rem)',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100%)'
    },
    '& .swiper': {
        width: '100%',
        height: '20rem'
    },
    '& .swiper-slide': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:first-of-type': {
            paddingLeft: '1.25em'
        }
    },
    '& .swiper-button-disabled': {
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
}));

const ItemActionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '2.5rem',
    maxWidth: '32rem',
    flex: 1,
    height: '15rem',
    border: `0.0625rem solid ${alpha(theme.palette.text.primary, 0.12)}`,
    borderRadius: '0.125rem',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 0 1.25em 0.0625rem rgba(30, 136, 229, 0.48)'
    }
}));

const StackTitle = styled('div')(({ theme }) => ({
    padding: '0.625rem',
    color: '#fff',
    backgroundColor: '#000000',
    borderRadius: '0 0 0.625rem 0',
    fontSize: '1.25rem',
    fontWeight: 'bold'
}));

const StackListBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
}));

const StackListItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    width: '5.125rem',
    '& > div': {
        width: '100%',
        aspectRatio: '1',
        boxShadow: `0px 0px 1.25rem ${alpha(theme.palette.text.primary, 0.12)}`,
        borderRadius: '1rem',
        overflow: 'hidden',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    '& > span': {
        fontSize: '0.9375rem',
        color: alpha(theme.palette.text.primary, 0.68)
    }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '0',
    backgroundColor: 'rgba(30, 136, 229, 0.86)',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
        width: '3rem',
        height: '3rem'
    },
    '& svg': {
        color: '#fff',
        fontSize: '2rem'
    },
    '&:hover': {
        backgroundColor: 'rgba(30, 136, 229, 0.86)',
        '& svg': {
            color: '#fff',
            fontSize: '2rem'
        }
    },
    '&.prev': {
        [theme.breakpoints.up('sm')]: {
            left: '-3.75rem'
        },
        [theme.breakpoints.down('sm')]: {
            left: '0rem'
        }
    },
    '&.next': {
        [theme.breakpoints.up('sm')]: {
            right: '-3.75rem'
        },
        [theme.breakpoints.down('sm')]: {
            right: '0rem'
        }
    }
}));
