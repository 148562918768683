import { SvgIcon } from '@mui/material';

const CheckIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <path d="M14 0.5L4.7 10.7273L2 8.68225H0.5L4.7 15.5L15.5 0.5H14Z" fill="currentColor" fillOpacity="0.68" />
        </SvgIcon>
    );
};

export default CheckIcon;
