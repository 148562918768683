import { styled, alpha } from '@mui/material/styles';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        marginLeft: 'auto'
    }
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    '& span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 1
    }
}));

const LanguageMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { i18n, t } = useTranslation();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('LANG', lang);
        handleClose();
    };

    return (
        <Wrapper>
            <StyledButton
                id="language-button"
                aria-controls={open ? 'language-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <span>{t('language.title')}</span>
            </StyledButton>
            <StyledMenu
                id="language-menu"
                MenuListProps={{
                    'aria-labelledby': 'language-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => setLanguage('en')} disableRipple>
                    {t('language.english')}
                </MenuItem>
                <MenuItem onClick={() => setLanguage('ko')} disableRipple>
                    {t('language.korean')}
                </MenuItem>
                <MenuItem onClick={() => setLanguage('ja')} disableRipple>
                    {t('language.japanese')}
                </MenuItem>
            </StyledMenu>
        </Wrapper>
    );
};

export default LanguageMenu;
