import { Modal, Fade, Backdrop, IconButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1.75rem 2.5rem 2.875rem 2.5rem',
    maxWidth: '31.25rem',
    width: '95%',
    backgroundColor: theme.palette.background.default
}));

const ModalHead = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& h1': {
        fontSize: '1.5rem',
        fontweight: 'bold',
        letterSpacing: '0.1em'
    },
    '& svg': {
        fontSize: '1.75rem',
        color: '#000'
    }
}));

const ModalBody = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingTop: '2rem',
    '& input': {
        minHeight: '1.75rem',
        border: 'none',
        borderBottom: '0.125rem solid rgba(30, 136, 229, 0.48)',
        fontSize: '0.875rem',
        '&::placeholder': { color: alpha(theme.palette.text.primary, 0.48) },
        '&:focus': {
            outline: 'none'
        }
    }
}));

const TextPrimary = styled('span')(({ theme }) => ({
    fontSize: '0.875rem',
    color: alpha(theme.palette.text.primary, 0.48)
}));

const TextSecondary = styled('span')(({ theme }) => ({
    fontSize: '0.9375rem',
    color: alpha(theme.palette.text.primary, 0.68)
}));

const ModalFooter = styled('div')(({ theme }) => ({
    paddingTop: '2.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
        width: '7.5rem',
        height: '3rem',
        lineHeight: '3rem',
        border: `0.125rem solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontsize: '1.0625rem',
        fontWeight: 'bold',
        letterSpacing: '0.1em',
        cursor: 'pointer',
        '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        }
    }
}));

const EmailInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    '& svg': {
        color: theme.palette.primary.main,
        fontSize: '1.75rem'
    }
}));

const Contact = (props) => {
    const { open, onClose } = props;
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Fade in={open}>
                <Wrapper>
                    <ModalHead>
                        <IconButton onClick={onClose} sx={{ alignSelf: 'flex-end' }}>
                            <CloseIcon />
                        </IconButton>
                        <div>
                            <h1>{t('modal.contactUsModal.modalTitle')}</h1>
                            <TextPrimary>{t('modal.contactUsModal.subTitle')}</TextPrimary>
                        </div>
                    </ModalHead>
                    <form action="mailto:biz@good-block.com" method="get" encType="text/plain">
                        <ModalBody>
                            <input type="text" name="email" id="email" placeholder={t('modal.contactUsModal.email')} />
                            <input type="text" name="title" id="title" placeholder={t('modal.contactUsModal.title')} />
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder={t('modal.contactUsModal.content')}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <EmailInfo>
                                <EmailOutlinedIcon />
                                <TextSecondary>biz@good-block.com</TextSecondary>
                            </EmailInfo>
                            <button type="submit">{t('modal.contactUsModal.button')}</button>
                        </ModalFooter>
                    </form>
                </Wrapper>
            </Fade>
        </Modal>
    );
};

export default Contact;
