import { Container } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const FooterWrap = styled('footer')(({ theme }) => ({
    paddingTop: '3.75rem',
    paddingBottom: '2.75rem',
    backgroundColor: '#01579B',
    '& span, & a': {
        fontSize: '0.9375rem'
    }
}));

const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& a': {
        color: 'rgba(255, 255, 255, 0.86)',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    '& ul': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '3rem',
        margin: '0 auto',
        listStyle: 'none'
    },
    [theme.breakpoints.down('md')]: {
        '& ul': {
            paddingTop: '1rem',
            width: '100%',
            flexWrap: 'wrap',
            gap: '0'
        },
        '& li': {
            width: '50%',
            '&:last-of-type': {
                width: '100%'
            }
        }
    }
}));

const LogoBox = styled('div')(({ theme }) => ({
    maxWidth: '9.5rem',
    maxHeight: '2.5rem',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}));

const CopyRightBox = styled('div')(({ theme }) => ({
    paddingTop: '2.25rem',
    textAlign: 'center',
    '& > span': {
        color: 'rgba(255, 255, 255, 0.52)'
    }
}));

const Li = styled('li')(({ theme }) => ({
    '& a': {
        position: 'relative'
    },
    '& a::before': {
        content: '""',
        position: 'absolute',
        bottom: '-0.25rem',
        display: 'block',
        width: '0%',
        height: '0.25rem',
        backgroundColor: alpha(theme.palette.text.secondary, 0.86),
        transition: 'width 0.3s ease-in-out'
    },
    '&:hover a::before': {
        width: '100%'
    }
}));

const Footer = () => {
    const moveSection = (id) => {
        window.location.replace(`/#${id}`);
    };

    return (
        <FooterWrap>
            <Container maxWidth="xl">
                <InfoBox>
                    <LogoBox>
                        <img src={'https://meta.sodportal.io/hp/images/footer-logo.png'} />
                    </LogoBox>
                    <ul>
                        <Li onClick={() => moveSection('our-service')}>
                            <a>Our Service</a>
                        </Li>
                        <Li onClick={() => moveSection('tech-stack')}>
                            <a>Tech Stack</a>
                        </Li>
                        {/* <Li onClick={moveSection('clients-story')}>
                            <a>Clients story</a>
                        </Li> */}
                        <Li onClick={() => moveSection('about')}>
                            <a>About</a>
                        </Li>
                        <Li onClick={() => moveSection('partners-and-clients')}>
                            <a>Partners</a>
                        </Li>
                        <Li onClick={() => moveSection('news-and-issue')}>
                            <a>News</a>
                        </Li>
                    </ul>
                </InfoBox>
                <CopyRightBox>
                    <span>© GoodBlock LTD. All Rights reserved.</span>
                </CopyRightBox>
            </Container>
        </FooterWrap>
    );
};

export default Footer;
