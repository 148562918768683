import 'swiper/css';
import 'swiper/css/navigation';
import { AppBar, Box, Container, IconButton, MenuItem } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import LanguageMenu from '../components/common/LanguageMenu';
import About from '../components/home/About';
// import ClientsStory from '../components/home/ClientsStory';
import HomeBanner from '../components/home/HomeBanner';
import OurService from '../components/home/OurService';
import Partners from '../components/home/Partners';
import Team from '../components/home/Team';
import TechStack from '../components/home/TechStack';
import SideMenu from '../components/menu/SideMenu';
import NewsIssue from '../components/home/NewsIssue';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

const Home = () => {
    const { i18n, t } = useTranslation();
    const drawerRef = useRef(null);
    const mainRef = useRef();
    const appBarRef = useRef();

    const moveSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickHamburger = () => {
        drawerRef.current?.click();
    };

    const sections = useMemo(() => {
        const _sections = [
            { name: t('header.gnb1'), id: 'our-services' },
            { name: t('header.gnb2'), id: 'tech-stack' },
            { name: t('header.gnb3'), id: 'about' },
            { name: t('header.gnb4'), id: 'team' },
            { name: t('header.gnb5'), id: 'news-and-issues' },
            { name: t('header.gnb6'), id: 'partners-and-clients' }
        ];
        return _sections;
    }, [i18n.language]);

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const mainTitles = self.selector('.mainTitle');
            mainTitles.forEach((box) => {
                const tl = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: box,
                        start: 'bottom bottom',
                        end: 'top top',
                        onEnter: () => tl.play(),
                        onEnterBack: () => tl.play(),
                        onLeave: () => {
                            tl.reverse();
                        },
                        onLeaveBack: () => {
                            tl.reverse();
                        }
                    }
                });

                tl.from(box.querySelectorAll('.chars'), {
                    y: gsap.utils.wrap([-20, 20]),
                    filter: 'blur(4px)',
                    opacity: 0,
                    stagger: { each: 0.1, from: 'center' }
                });

                tl.to(box.querySelectorAll('.chars'), {
                    delay: 0.5,
                    ease: 'power3.inOut'
                });
            });

            const subTitles = self.selector('.subTitle');
            subTitles.forEach((box) => {
                const tl = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: box,
                        start: 'bottom bottom',
                        end: 'top top',
                        onEnter: () => tl.restart(),
                        onEnterBack: () => tl.restart(),
                        onLeave: () => {
                            tl.reverse();
                        },
                        onLeaveBack: () => {
                            tl.reverse();
                        }
                    }
                });

                tl.from(box.querySelectorAll('.chars'), {
                    y: gsap.utils.wrap([-20, 20]),
                    filter: 'blur(4px)',
                    opacity: 0,
                    stagger: { each: 0.05, from: 'start' }
                });

                tl.to(box.querySelectorAll('.chars'), {
                    ease: 'power3.inOut'
                });
            });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    useEffect(() => {
        // 현재 언어에 따라 문서의 lang 속성을 업데이트
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);

    return (
        <>
            <Helmet>
                <title>{t('metaTag.title')}</title>
                <meta name="description" content={t('metaTag.description')} />
                <meta property="og:site_name" content={t('metaTag.ltdTitle')} />
                <meta property="og:title" content={t('metaTag.ltdTitle')} />
                <meta property="twitter:site" content={t('metaTag.ltdTitle')} />
                <meta property="twitter:title" content={t('metaTag.ltdTitle')} />
                <meta property="twitter:description" content={t('metaTag.description')} />
                <meta property="og:description" content={t('metaTag.description')} />
            </Helmet>
            <StyledAppBar className="topNavBar" ref={appBarRef}>
                <StyledContainer maxWidth="xl">
                    <Link to="/">
                        <LogoWrapper>
                            <img src={'https://meta.sodportal.io/hp/images/gnb-logo.png'} />
                        </LogoWrapper>
                    </Link>
                    <MenuWrapper>
                        {sections.map((section) => (
                            <MenuItem key={section.id} onClick={() => moveSection(section.id)}>
                                <MenuText>{section.name}</MenuText>
                            </MenuItem>
                        ))}
                    </MenuWrapper>
                    <LanguageMenu />
                    <IconButton
                        sx={{ display: { xs: 'flex', lg: 'none' }, '&:hover': { backgroundColor: 'transparent' } }}
                        onClick={handleClickHamburger}
                    >
                        <MenuIcon>
                            <div />
                            <div />
                            <div />
                        </MenuIcon>
                    </IconButton>
                    <SideMenu drawerRef={drawerRef} sections={sections} />
                </StyledContainer>
            </StyledAppBar>
            <HomeBanner />
            <Container maxWidth="xl" ref={mainRef}>
                <Box id="our-services" sx={{ pt: '5rem' }}>
                    <OurService />
                </Box>
                <Box id="tech-stack" sx={{ pt: '6.25rem' }}>
                    <TechStack />
                </Box>
                <Box id="about" sx={{ pt: '6.25rem' }}>
                    <About />
                </Box>
                <Box id="team" sx={{ pt: '6.25rem' }}>
                    <Team />
                </Box>
                <Box id="news-and-issues" sx={{ pt: '6.25rem' }}>
                    <NewsIssue />
                </Box>
                <Box id="partners-and-clients" sx={{ pt: '6.25rem' }}>
                    <Partners />
                </Box>
            </Container>
        </>
    );
};

export default Home;

const StyledAppBar = styled(AppBar)({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center'
});

const StyledContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.375rem 0',
    maxHeight: '5rem'
});

const LogoWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '& img': {
        width: '8.5rem',
        height: 'auto',
        objectFit: 'contain'
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '0.5rem'
    }
}));

const MenuWrapper = styled('ul')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1.75rem',
    '& li': {
        padding: 0,
        '&:hover': {
            background: 'none'
        }
    },
    [theme.breakpoints.down('lg')]: {
        display: 'none'
    }
}));

const MenuText = styled('span')(({ theme }) => ({
    position: 'relative',
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    color: alpha(theme.palette.text.primary, 0.86),
    '&:before': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        display: 'block',
        width: '0%',
        height: '0.25rem',
        backgroundColor: theme.palette.primary.main,
        transition: 'width 0.3s ease-in-out'
    },
    '&:hover:before': {
        width: '100%'
    }
}));

const MenuIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '2rem',
    height: '1.25rem',
    '& > div': {
        width: '100%',
        height: '2px',
        backgroundColor: alpha(theme.palette.text.primary, 0.68),
        transition: 'width 0.3s ease, color 0.5s ease'
    },
    '& > div:nth-of-type(3)': { width: '70%' },
    '&:hover': {
        '& > div': {
            backgroundColor: theme.palette.primary.main
        },
        '& > div:nth-of-type(2)': { width: '70%' },
        '& > div:nth-of-type(3)': { width: '100%' }
    }
}));
