import { SvgIcon } from '@mui/material';

const ViewMoreIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 57 11">
            <path
                d="M1 10H56.5L50.5 1"
                stroke="currentColor"
                strokeOpacity="0.48"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};

export default ViewMoreIcon;
