import { Grid, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import SplitTextIntoDivs from '../common/SplitTextIntoDivs';
import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

const partners = [
    { name: '아크로CC', imageUrl: '/assets/images/partners/acrocc.webp' },
    { name: '143ent', imageUrl: '/assets/images/partners/ent143.webp' },
    { name: 'sod', imageUrl: 'https://meta.sodportal.io/hp/images/partners/sod.png' },
    { name: 'sakura-nft', imageUrl: 'https://meta.sodportal.io/hp/images/partners/sakura-nft.png' },
    { name: 'skydance', imageUrl: 'https://meta.sodportal.io/hp/images/partners/skydance.png' },
    { name: 'bitgate', imageUrl: 'https://meta.sodportal.io/hp/images/partners/bitgate.png' },
    { name: 'pentabase', imageUrl: 'https://meta.sodportal.io/hp/images/partners/pentabase.png' },
    { name: 'berryStore', imageUrl: 'https://live.staticflickr.com/65535/52565892172_58168b4297_w.jpg', md: 1.5 },
    { name: 'kpx', imageUrl: 'https://live.staticflickr.com/65535/52566632724_3332d2d997_o.png', md: 1.5 },
    { name: 'blockwiseratings', imageUrl: 'https://meta.sodportal.io/hp/images/partners/blockwiseratings.png' },
    { name: 'moontrex', imageUrl: 'https://meta.sodportal.io/hp/images/partners/moontrex.png' }
];

const workedWith = [
    { name: 'fit300', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-1.png' },
    { name: 'milksCoin', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-2.png' },
    { name: 'name3', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-3.png' },
    { name: 'angelium', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-4.png' },
    { name: 'name5', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-5.png' },
    { name: 'finple', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-6.png' },
    { name: 'fs', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-8.png' },
    { name: 'ask', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-19.png' },
    { name: 'patron', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-16.png' },
    { name: 'dragonereum', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-15.png' },
    { name: 'imperial', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-11.png' },
    { name: 'whitestoneCoin', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-12.png' },
    { name: 'electroShare', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-18.png' },
    { name: 'fuzeX', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-21.png' },
    { name: 'name14', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-14.png' },
    { name: 'gxc', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-13.png' },
    { name: 'coineal', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-22.png' },
    { name: 'imo', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-10.jpeg' },
    { name: 'xHive', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-20.png' },
    { name: 'smartValor', imageUrl: 'https://meta.sodportal.io/hp/images/worked-with/worked-with-17.png' }
];

const disX = gsap.utils.distribute({
    base: -200,
    amount: 400
});

const Partners = () => {
    const { t } = useTranslation();
    const mainRef = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                delay: 0.5,
                paused: true,
                scrollTrigger: {
                    trigger: '.partners-co',
                    start: 'top bottom',
                    end: 'bottom top',
                    onEnter: () => tl.play()
                    // onEnterBack: () => tl.play(),
                    // onLeave: () => {
                    //     tl.reverse();
                    // },
                    // onLeaveBack: () => {
                    //     tl.reverse();
                    // }
                }
            });
            tl.add([
                gsap.from('.partner-co', {
                    y: gsap.utils.wrap([-20, 20]),
                    ease: 'power1,inOut',
                    filter: 'blur(4px)',
                    autoAlpha: 0,
                    stagger: 0.2
                })
            ]);
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                delay: 0.5,
                paused: true,
                scrollTrigger: {
                    trigger: '.worked-with-co',
                    start: 'top bottom',
                    end: 'bottom top',
                    onEnter: () => tl.play()
                    // onEnterBack: () => tl.play(),
                    // onLeave: () => {
                    //     tl.reverse();
                    // },
                    // onLeaveBack: () => {
                    //     tl.reverse();
                    // }
                }
            });
            tl.add([
                gsap.from('.worked-co', {
                    y: gsap.utils.wrap([-20, 20]),
                    ease: 'power1,inOut',
                    filter: 'blur(4px)',
                    autoAlpha: 0,
                    stagger: 0.2
                })
            ]);
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, []);

    return (
        <Wrapper ref={mainRef}>
            <InnerWrap>
                <Typography
                    className={'mainTitle'}
                    variant="h1"
                    sx={{
                        letterSpacing: '0.1em',
                        textAlign: 'center',
                        maxWidth: '100%',
                        fontSize: { xs: '2.5rem', sm: '3.75rem' }
                    }}
                >
                    <SplitTextIntoDivs text={t('partnersSection.sectionTitle')} className="chars" />
                </Typography>
                <SubTitle className={'subTitle'} variant="subtitle1">
                    <SplitTextIntoDivs text={t('partnersSection.subTitle1')} className="chars" />
                </SubTitle>
                <StyledGridContainer
                    className="partners-co"
                    container
                    maxWidth="lg"
                    spacing={{ xs: 4, sm: 5, md: 7.5 }}
                >
                    {partners.map((partner, index) => (
                        <StyledGridItem item xs={6} md={partner.md || 3} key={partner.name} className="partner-co">
                            <img
                                src={partner.imageUrl}
                                style={{ maxHeight: partner.name === '143ent' ? '64px' : '5rem' }}
                            />
                        </StyledGridItem>
                    ))}
                </StyledGridContainer>
                <TextPrimary>{t('partnersSection.subTitle2')}</TextPrimary>
                <WorkedWithBox className="worked-with-co">
                    {workedWith.map((company, index) => (
                        <CompanyBox key={company.name} className="worked-co">
                            <img src={company.imageUrl} />
                        </CompanyBox>
                    ))}
                </WorkedWithBox>
            </InnerWrap>
        </Wrapper>
    );
};

export default Partners;

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const InnerWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '6.25rem',
    paddingBottom: '6.25rem',
    width: '100vw',
    backgroundImage: 'url(https://meta.sodportal.io/hp/images/worked-with-cover.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    letterSpacing: '0.1em',
    textAlign: 'center'
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    padding: '5rem 2rem 5.875rem 2rem',
    paddingTop: '5rem',
    paddingBottom: '5.875rem',
    width: '90%'
}));
const StyledGridItem = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
        height: 'auto',
        width: '100%',
        maxHeight: '5rem',
        objectFit: 'contain'
    }
}));

const TextPrimary = styled('h3')(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: alpha(theme.palette.text.primary, 0.48)
}));

const WorkedWithBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5rem',
    paddingTop: '3.75rem',
    position: 'relative',
    maxWidth: '100rem',
    width: '90%'
}));

const CompanyBox = styled('div')(({ theme }) => ({
    width: 'fit-content',
    '& img': {
        maxHeight: '5.625rem',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            maxHeight: '3rem'
        }
    }
}));
