import { gsap } from 'gsap';
import AppRouter from './AppRouter';
import Footer from './components/menu/Footer';
import ScrollMenu from './components/menu/ScrollMenu';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { HelmetProvider } from 'react-helmet-async';

gsap.registerPlugin(ScrollTrigger);

const App = () => {
    return (
        <HelmetProvider>
            <div className="fixBody">
                <AppRouter />
                <Footer />
                <ScrollMenu />
            </div>
        </HelmetProvider>
    );
};

export default App;
