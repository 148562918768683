// 버튼 공통컴포넌트
// 높이 60px 버튼에 적합
// title : 버튼에 들어갈 텍스트
// variant : contained 색이 꽉찬 버튼
// variant : outlined 아웃라인 버튼
// variant : disabled 비활성화 버튼
// onClick : onClick 바인딩
// use-case 메인페이지 버튼
import { Button } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const ButtonContained = styled(Button)(({ theme, style }) => ({
    minWidth: '9.75rem',
    height: '3rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    lineHeight: '3rem',
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    },
    '& .MuiButton-endIcon': {
        marginLeft: '0.125rem'
    },
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
        color: theme.palette.text.secondary,
        fontSize: '1.0625rem'
    },
    ...style
}));

const ButtonOutlined = styled(Button)(({ theme, style }) => ({
    minWidth: '9.75rem',
    height: '3rem',
    border: `0.125rem solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    lineHeight: '3rem',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        '& .MuiButton-endIcon>*:nth-of-type(1)': {
            color: theme.palette.text.secondary
        }
    },
    '& .MuiButton-endIcon': {
        marginLeft: '0.125rem'
    },
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
        color: theme.palette.primary.main,
        fontSize: '1.0625rem'
    },
    ...style
}));

const ButtonCanceled = styled(Button)(({ theme, style }) => ({
    backgroundColor: '#A7A4AD',
    height: '3.75rem',
    color: 'rgba(255, 255, 255, 0.86)',
    boxShadow: 'inset 0.125rem 0.125rem 0.375rem rgba(255, 255, 255, 0.48)',
    '&:hover': {
        backgroundColor: '#A7A4AD',
        boxShadow: `0.625rem 1rem 1.25rem ${alpha(
            theme.palette.text.primary,
            0.12
        )}, inset 0.125rem 0.125rem 0.375rem rgba(255, 255, 255, 0.48)`
    },
    ...style
}));

const ButtonDisabled = styled(Button)(({ theme, style }) => ({
    backgroundColor: '#666666',
    boxShadow:
        '0.125rem 0.25rem 1.25rem rgba(255, 255, 255, 0.16), inset 0.125rem 0.125rem 0.375rem rgba(255, 255, 255, 0.48)',
    height: '3.75rem',
    '&.Mui-disabled': {
        color: '#888888'
    },
    ...style
}));

const Button1 = (props) => {
    const { variant, title, style, onClick, type, disabled, startIcon, endIcon } = props;

    if (variant === 'contained') {
        return (
            <ButtonContained
                type={type}
                style={style}
                onClick={onClick}
                disabled={disabled}
                endIcon={endIcon}
                startIcon={startIcon}
            >
                {title}
            </ButtonContained>
        );
    }

    if (variant === 'outlined') {
        return (
            <ButtonOutlined
                type={type}
                style={style}
                onClick={onClick}
                disabled={disabled}
                endIcon={endIcon}
                startIcon={startIcon}
            >
                {title}
            </ButtonOutlined>
        );
    }

    if (variant === 'canceled') {
        return (
            <ButtonCanceled
                type={type}
                style={style}
                onClick={onClick}
                disabled={disabled}
                endIcon={endIcon}
                startIcon={startIcon}
            >
                {title}
            </ButtonCanceled>
        );
    }

    if (variant === 'disabled') {
        return (
            <ButtonDisabled
                type={type}
                style={style}
                onClick={onClick}
                endIcon={endIcon}
                startIcon={startIcon}
                disabled
            >
                {title}
            </ButtonDisabled>
        );
    }
};

Button1.defaultProps = {
    variant: 'contained',
    title: undefined,
    style: undefined,
    onClick: undefined,
    type: 'button',
    disabled: false,
    startIcon: undefined,
    endIcon: undefined
};

export default Button1;
