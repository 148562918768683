import { Grid, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Button1 from '../common/Button/Button1';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect, useRef, useState } from 'react';
import BusinessConsulting from '../modal/BusinessConsulting';
import WebDevelopment from '../modal/WebDevelopment';
import PlatformDevelopment from '../modal/PlatformDevelopment';
import OtherServices from '../modal/OtherServices';
import SplitTextIntoDivs from '../common/SplitTextIntoDivs';
import { gsap } from 'gsap';

const modalInit = {
    business: false,
    webDev: false,
    platformDev: false,
    otherServices: false
};

const disX = gsap.utils.distribute({
    base: -200,
    amount: 400
});

const OurService = () => {
    const { t } = useTranslation();
    const [viewModal, setViewModal] = useState(modalInit);
    const mainRef = useRef();

    const showModal = (current) => {
        setViewModal({ ...modalInit, [current]: true });
    };

    const closeModal = () => {
        setViewModal(modalInit);
    };

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                delay: 0.5,
                paused: true,
                scrollTrigger: {
                    trigger: '.grid-container',
                    start: 'top bottom',
                    end: 'center top',
                    onEnter: () => tl.play()
                    // onEnterBack: () => tl.play(),
                    // onLeave: () => {
                    //     tl.reverse();
                    // },
                    // onLeaveBack: () => {
                    //     tl.reverse();
                    // }
                }
            });
            tl.add([
                gsap.from('.image-box', {
                    x: disX,
                    filter: 'blur(10px)',
                    autoAlpha: 0,
                    stagger: { each: 0.1, from: 'center' }
                }),
                gsap.from('.desc-box', {
                    delay: 0.5,
                    scale: 0,
                    filter: 'blur(10px)',
                    ease: 'back',
                    autoAlpha: 0,
                    stagger: { each: 0.2 }
                })
            ]);

            tl.to('.image-box', {
                delay: 0.5,
                duration: 2,
                autoAlpha: 1,
                ease: 'power3.inOut'
            });
        }, mainRef);

        return () => {
            ctx.revert();
        };
    }, []);

    return (
        <Wrapper ref={mainRef}>
            <Typography
                className={'mainTitle'}
                variant="h1"
                sx={{
                    letterSpacing: '0.1em',
                    textAlign: 'center',
                    maxWidth: '100%',
                    fontSize: { xs: '2.5rem', sm: '3.75rem' }
                }}
            >
                <SplitTextIntoDivs text={t('ourServiceSection.sectionTitle')} className="chars" />
            </Typography>
            <SubTitle className={'subTitle'} variant="subtitle1">
                <SplitTextIntoDivs text={t('ourServiceSection.subTitle')} className="chars" />
            </SubTitle>
            <Grid className="grid-container" container sx={{ paddingTop: '2.5rem', maxWidth: { xs: 'md', lg: 'xl' } }}>
                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <ItemActionBox className={'desc-box'}>
                                <TextPrimary>{t('ourServiceSection.item1MainTitle')}</TextPrimary>
                                <TextSecondary>{t('ourServiceSection.item1SubTitle')}</TextSecondary>
                                <Button1
                                    variant="outlined"
                                    title="View more"
                                    endIcon={<AddIcon />}
                                    onClick={() => showModal('business')}
                                />
                            </ItemActionBox>
                        </Grid>
                        <Grid item xs={6} className={'image-box'}>
                            <ItemImageBox className={'box-1 item-image-box'}></ItemImageBox>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item xs={6} sx={{ order: { xs: 2, lg: 1 } }}>
                            <ItemActionBox className={'desc-box'}>
                                <TextPrimary>{t('ourServiceSection.item2MainTitle')}</TextPrimary>
                                <TextSecondary>{t('ourServiceSection.item2SubTitle')}</TextSecondary>
                                <Button1
                                    variant="outlined"
                                    title="View more"
                                    endIcon={<AddIcon />}
                                    onClick={() => showModal('webDev')}
                                />
                            </ItemActionBox>
                        </Grid>
                        <Grid item xs={6} sx={{ order: { xs: 1, lg: 2 } }} className={'image-box'}>
                            <ItemImageBox className={'box-2 item-image-box'}>
                                <div>
                                    <img src="https://meta.sodportal.io/hp/images/our-service/item-2.png" />
                                </div>
                            </ItemImageBox>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item xs={6} sx={{ order: { xs: 2, lg: 1 } }} className={'image-box'}>
                            <ItemImageBox className={'box-3 item-image-box'}>
                                <div>
                                    <img src="https://meta.sodportal.io/hp/images/our-service/item-3.png" />
                                </div>
                            </ItemImageBox>
                        </Grid>
                        <Grid item xs={6} sx={{ order: { xs: 1, lg: 2 } }}>
                            <ItemActionBox className={'desc-box'}>
                                <TextPrimary>{t('ourServiceSection.item3MainTitle')}</TextPrimary>
                                <TextSecondary>{t('ourServiceSection.item3SubTitle')}</TextSecondary>
                                <Button1
                                    variant="outlined"
                                    title="View more"
                                    endIcon={<AddIcon />}
                                    onClick={() => showModal('platformDev')}
                                />
                            </ItemActionBox>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item xs={6} className={'image-box'}>
                            <ItemImageBox className={'box-4 item-image-box'}></ItemImageBox>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemActionBox className={'desc-box'}>
                                <TextPrimary>{t('ourServiceSection.item4MainTitle')}</TextPrimary>
                                <TextSecondary>{t('ourServiceSection.item4SubTitle')}</TextSecondary>
                                <Button1
                                    variant="outlined"
                                    title="View more"
                                    endIcon={<AddIcon />}
                                    onClick={() => showModal('otherServices')}
                                />
                            </ItemActionBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {viewModal.business && (
                <BusinessConsulting open={viewModal.business} onClose={closeModal} className={'detail-modal'} />
            )}
            {viewModal.webDev && (
                <WebDevelopment open={viewModal.webDev} onClose={closeModal} className={'detail-modal'} />
            )}
            {viewModal.platformDev && (
                <PlatformDevelopment open={viewModal.platformDev} onClose={closeModal} className={'detail-modal'} />
            )}
            {viewModal.otherServices && (
                <OtherServices open={viewModal.otherServices} onClose={closeModal} className={'detail-modal'} />
            )}
        </Wrapper>
    );
};

export default OurService;

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    letterSpacing: '0.1em',
    textAlign: 'center'
}));

const TextPrimary = styled('span')(({ theme }) => ({
    maxWidth: '95%',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
}));

const TextSecondary = styled('p')(({ theme }) => ({
    maxWidth: '95%',
    color: alpha(theme.palette.text.primary, 0.68),
    fontSize: '0.9375rem',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
}));

const ItemActionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '0.5rem',
    aspectRatio: '1.09'
}));

const ItemImageBox = styled('div')(({ theme }) => ({
    flex: 1,
    backgroundColor: 'grey',
    height: '100%',
    '&.box-1': {
        background: `url(https://meta.sodportal.io/hp/images/our-service/cover-1.png) rgba(30, 136, 229, 0.2)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundBlendMode: 'soft-light'
    },
    '&.box-2': {
        background: `url(https://meta.sodportal.io/hp/images/our-service/cover-2.png) #42A5F5`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundBlendMode: 'screen',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            transform: 'translate(-30%,15%) scale(1.2,1.2)'
        }
    },
    '&.box-3': {
        background: `url(https://meta.sodportal.io/hp/images/our-service/cover-3.png) #000000`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundBlendMode: 'screen',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            transform: 'translate(-12.5%,40%)',
            [theme.breakpoints.down('lg')]: {
                transform: 'translate(-8%,40%)'
            }
        }
    },
    '&.box-4': {
        background: `url(https://meta.sodportal.io/hp/images/our-service/cover-4.png) rgba(30, 136, 229, 0.2)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundBlendMode: 'soft-light'
    }
}));
