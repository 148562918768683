import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { IconButton, MenuItem } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100vw',
    backgroundColor: alpha(theme.palette.primary.main, 0.9)
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginRight: '1rem',
    alignSelf: 'flex-end',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'rotate(90deg)'
    },
    '& svg': {
        color: '#fff',
        fontSize: '2.5rem'
    }
}));

const Ul = styled('ul')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center'
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': { backgroundColor: 'transparent' }
}));

const MenuText = styled('span')(({ theme }) => ({
    position: 'relative',
    fontSize: '3rem',
    fontWeight: 'bold',
    color: '#fff',
    '&:before': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        display: 'block',
        width: '0%',
        height: '0.25rem',
        backgroundColor: '#fff',
        transition: 'width 0.3s ease-in-out'
    }
}));

const SideMenu = (props) => {
    const { drawerRef, sections } = props;

    const [state, setState] = useState({
        right: false
    });

    // 모달 토글 단축키
    const toggleDrawer = (anchor, open) => () => {
        setState({ [anchor]: open });
    };

    const moveSection = (id) => {
        setState({ right: false });
        return setTimeout(() => {
            window.location.replace(`/#${id}`);
        }, 300);
    };

    const list = (anchor) => (
        <Wrapper>
            <StyledIconButton onClick={toggleDrawer('right', false)}>
                <CloseIcon />
            </StyledIconButton>

            <Ul sx={{ pt: '0.5rem' }}>
                {sections.map((section) => (
                    <StyledMenuItem
                        key={section.id}
                        onClick={() => moveSection(section.id)}
                        sx={{
                            '&:hover > span:before': {
                                width: '100%'
                            }
                        }}
                    >
                        <MenuText>{section.name}</MenuText>
                    </StyledMenuItem>
                ))}
            </Ul>
        </Wrapper>
    );

    return (
        <>
            <button style={{ display: 'none' }} ref={drawerRef} onClick={toggleDrawer('right', true)}></button>
            <Drawer
                anchor={'right'}
                open={state.right}
                onClose={toggleDrawer('right', false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: 'transparent'
                    }
                }}
            >
                {list('right')}
            </Drawer>
        </>
    );
};

export default SideMenu;
