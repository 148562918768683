import { Box, Skeleton, Typography } from '@mui/material';
import { Autoplay, Pagination } from 'swiper';
import { styled, alpha } from '@mui/material/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { getArticles } from '../../lib/api/requestApi';
import ViewMoreIcon from '../icon/ViewMoreIcon';
import SplitTextIntoDivs from '../common/SplitTextIntoDivs';
import { Swiper, SwiperSlide } from 'swiper/react';

const NEWS = [
    {
        id: 'a1',
        image_url: 'https://miro.medium.com/v2/0*XVmoE1oegjnJCmrh.png',
        title: {
            ko: 'HS88-굿블록, ‘블록체인 혁신 비즈니스 모델’ 위한 MOU 체결',
            en: 'HS88-GoodBlock Signed MOU for ‘Blockchain Innovation Business Model’'
        },
        published_at: '2022-01-27 15:17:00',
        url: {
            ko: 'http://www.it-b.co.kr/news/articleView.html?idxno=56789',
            en: 'https://medium.com/@goodblock/hs88-굿블록-블록체인-혁신-비즈니스-모델-위한-mou-체결-e4bfd6505120'
        },
        origin: {
            ko: '아이티비즈',
            en: 'ITBizNews'
        }
    },
    {
        id: 'a2',
        image_url: 'https://miro.medium.com/1*CHGSEAs8wYXu7Y21PW7sUw.png',
        title: {
            ko: '‘블록와이스레이팅스’와 ‘굿블록’, NFT 검증 및 평가부문’ 협력 위해 MOU 추진',
            en: 'Promote MOU for cooperation in ‘BLOCKWISERATINGS’ and ‘GoodBlock’ NFT verification and evaluation sector'
        },
        published_at: '2022-11-15 16:28:00',
        url: {
            ko: 'http://coinpannews.com/블록와이스레이팅스와-굿블럭-nft-검증-및-평',
            en: 'https://medium.com/@goodblock/promote-mou-for-cooperation-in-blockwiseratings-and-goodblock-nft-verification-and-evaluation-b9d306b7fa86'
        },
        origin: {
            ko: '코인판뉴스',
            en: 'Coinpan News'
        }
    },
    {
        id: 'b1',
        image_url: 'https://cdn.nextdaily.co.kr/news/photo/202308/221054_321585_5851.jpg',
        title: {
            ko: "블록체인 개발&컨설팅 기업 '굿블록', 가상자산 전문인력 통한 해법 제시",
            en: "Blockchain Development & Consulting Firm 'GoodBlock', Proposes Solutions Through Virtual Asset Experts"
        },
        published_at: '2023-08-04 17:04:00',
        url: {
            ko: 'http://www.nextdaily.co.kr/news/articleView.html?idxno=221054',
            en: 'http://www.nextdaily.co.kr/news/articleView.html?idxno=221054'
        },
        origin: {
            ko: '넥스트데일리',
            en: 'Next Daily'
        }
    },
    {
        id: 'b2',
        image_url: 'https://www.it-b.co.kr/news/photo/202308/69876_67585_2721.jpg',
        title: {
            ko: '굿블록, 국내 규제 맞춤형 증권형 토큰∙NFT 거래 솔루션 입지 다져',
            en: 'GoodBlock, Solidifying its Position in Compliant Security Token & NFT Trading Solutions in Korea'
        },
        published_at: '2023-08-11 13:27:00',
        url: {
            ko: 'http://www.it-b.co.kr/news/articleView.html?idxno=69876',
            en: 'http://www.it-b.co.kr/news/articleView.html?idxno=69876'
        },
        origin: {
            ko: '아이티비즈',
            en: 'ITBizNews'
        }
    },
    {
        id: 'b3',
        image_url: 'https://cdn.stardailynews.co.kr/news/photo/202310/423726_423793_130.jpg',
        title: {
            ko: '굿블록, 베리스토어 블록체인 생태계 안전한 확장 위한 기술지원',
            en: 'GoodBlock, Technical Support for Safe Expansion of the BerryStore Blockchain Ecosystem'
        },
        published_at: '2023-10-04 14:09:00',
        url: {
            ko: 'http://www.stardailynews.co.kr/news/articleView.html?idxno=423726',
            en: 'http://www.stardailynews.co.kr/news/articleView.html?idxno=423726'
        },
        origin: {
            ko: '스타데일리뉴스',
            en: 'Star Daily News'
        }
    },
    {
        id: 'b4',
        image_url: 'http://www.it-b.co.kr/news/photo/202311/72068_69786_355.png',
        title: {
            ko: '굿블록, 영암 아크로CC와 파트너쉽 체결',
            en: 'GoodBlock Forms Partnership with Yeongam Acro CC'
        },
        published_at: '2023-11-24 16:35:00',
        url: {
            ko: 'http://www.it-b.co.kr/news/articleView.html?idxno=72068',
            en: 'http://www.it-b.co.kr/news/articleView.html?idxno=72068'
        },
        origin: {
            ko: '아이티비즈',
            en: 'ITBizNews'
        }
    },
    {
        id: 'b5',
        image_url: 'https://www.cctvnews.co.kr/news/photo/202311/236376_239851_1039.jpg',
        title: {
            ko: '굿블록, 143 엔터테인먼트와 업무협약 체결 ...블록체인 사업 확장 나서',
            en: 'GoodBlock Enters Blockchain Business Expansion by Signing Business Agreement with 143 Entertainment'
        },
        published_at: '2023-11-24 18:10:00',
        url: {
            ko: 'https://www.cctvnews.co.kr/news/articleView.html?idxno=236376',
            en: 'https://www.cctvnews.co.kr/news/articleView.html?idxno=236376'
        },
        origin: {
            ko: 'CCTV뉴스',
            en: 'CCTV News'
        }
    },
    {
        id: 'b6',
        image_url: '/assets/images/good-block-certificate.webp',
        title: {
            ko: '굿블록, \'2024년 Top 10 웹3 기술 솔루션 제공업체\' 선정',
            en: 'GoodBlock | Top Web3 Technology Solutions Provider in APAC-2024'
        },
        published_at: '2024-08-01 14:10:00',
        url: {
            ko: 'https://www.apacciooutlook.com/goodblock',
            en: 'https://www.apacciooutlook.com/goodblock'
        },
        origin: {
            ko: 'APAC CIO Outlook',
            en: 'APAC CIO Outlook'
        }
    }
];

const pageNavigation = {
    clickable: true,
    renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
    }
};

const NewsIssue = () => {
    const [articles, setArticles] = useState(null);
    const { i18n, t } = useTranslation();
    const swiperRef = useRef(null);
    const [swiperIndex, setSwiperIndex] = useState(0);

    const getArticleList = async () => {
        // const result = await getArticles('@goodblock');

        // const articleList = result.map((item) => {
        //     const { id, image_url, title, published_at, url } = item;
        //     return { id, image_url, title: { ko: title, en: title }, published_at, url };
        // });
        // const AllArticles = [...articleList, ...NEWS];
        const AllArticles = [...NEWS];

        setArticles(AllArticles.sort((a, b) => new Date(b.published_at) - new Date(a.published_at)));
    };

    const formatDate = useCallback((dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }, []);

    const handleClickNews = useCallback((url) => {
        window.open(url, '_blank');
    }, []);

    useEffect(() => {
        getArticleList();
    }, []);

    return (
        <Wrapper>
            <Typography
                className={'mainTitle'}
                variant="h1"
                sx={{
                    letterSpacing: '0.1em',
                    textAlign: 'center',
                    maxWidth: '100%',
                    fontSize: { xs: '2.5rem', sm: '3.75rem' }
                }}
            >
                <SplitTextIntoDivs text={t('newsAndIssueSection.sectionTitle')} className="chars" />
            </Typography>
            <SubTitle className={'subTitle'} variant="subtitle1" sx={{ paddingBottom: '2rem' }}>
                <SplitTextIntoDivs text={t('newsAndIssueSection.subTitle')} className="chars" />
            </SubTitle>
            <SwiperWrap>
                <Swiper
                    pagination={pageNavigation}
                    modules={[Pagination, Autoplay]}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    // autoplay={false}
                    breakpoints={{ 1280: { slidesPerView: 3 }, 960: { slidesPerView: 2 }, 480: { slidesPerView: 1 } }}
                    onSlideChange={(e) => setSwiperIndex(e.realIndex || 0)}
                >
                    {articles ? (
                        articles.map((item) => (
                            <SwiperSlide key={item.id}>
                                <ItemBox
                                    onClick={() => handleClickNews(i18n.language === 'ko' ? item.url.ko : item.url.en)}
                                >
                                    <ItemImageBox>
                                        <img src={item.image_url} />
                                    </ItemImageBox>
                                    <ItemContentsBox>
                                        <p>{i18n.language === 'ko' ? item.title.ko : item.title.en}</p>
                                        <span>{i18n.language === 'ko' ? item.origin.ko : item.origin.en}</span>
                                        <Box
                                            sx={(theme) => ({
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                paddingTop: '0.25rem'
                                            })}
                                        >
                                            <span>{formatDate(item.published_at)}</span>
                                            <ViewMoreButton
                                                href={i18n.language === 'ko' ? item.url.ko : item.url.en}
                                                target="_blank"
                                            >
                                                <span>more</span>
                                                <ViewMoreIcon />
                                            </ViewMoreButton>
                                        </Box>
                                    </ItemContentsBox>
                                </ItemBox>
                            </SwiperSlide>
                        ))
                    ) : (
                        <>
                            {['bone1', 'bone2', 'bone3'].map((el) => (
                                <SwiperSlide key={el}>
                                    <ItemBox>
                                        <ItemImageBox>
                                            <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                                        </ItemImageBox>
                                        <ItemContentsBox>
                                            <Skeleton variant="text" sx={{ width: '100%', height: '60px' }} />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Skeleton variant="text" sx={{ width: '100%' }} />
                                            </Box>
                                        </ItemContentsBox>
                                    </ItemBox>
                                </SwiperSlide>
                            ))}
                        </>
                    )}
                </Swiper>
            </SwiperWrap>
        </Wrapper>
    );
};

export default NewsIssue;

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.68),
    letterSpacing: '0.1em',
    textAlign: 'center'
}));

const ItemBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem',
    width: '100%',
    borderRadius: '0.125rem',
    boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    cursor: 'pointer',
    '&:hover img': {
        transform: 'scale(1.1)'
    }
}));

const ItemImageBox = styled('div')(({ theme }) => ({
    width: '100%',
    height: '17.5rem',
    overflow: 'hidden',
    '& > img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        transition: 'transform 0.5s ease'
    }
}));

const ItemContentsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.25rem',
    padding: '0.75rem',
    '& > p': {
        display: '-webkit-box',
        width: '100%',
        height: '3.75rem',
        color: 'rgba(11, 12, 12, 0.86)',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.25rem',
        fontWeight: 'bold'
    },
    '& span': {
        fontSize: '0.9375rem',
        color: alpha(theme.palette.text.primary, 0.48)
    }
}));

const ViewMoreButton = styled('a')(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    '& > svg': {
        position: 'absolute',
        right: '0',
        bottom: '0',
        width: '3.75rem',
        height: 'auto',
        color: alpha(theme.palette.text.primary, 0.48)
    },
    '& > span': {
        paddingRight: '1.75rem',
        fontSize: '1rem',
        color: alpha(theme.palette.text.primary, 0.48)
    },
    '&:hover': {
        '& > svg, & > span': {
            color: theme.palette.primary.main
        }
    }
}));
const SwiperWrap = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'relative',
    '& .swiper': {
        width: '100%'
    },
    '& .swiper-wrapper': {
        paddingBottom: '2rem'
    },
    '& .swiper-slide': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .swiper-pagination': {
        bottom: '0.5rem'
    },
    '& .swiper-pagination-bullet': {
        width: '0.5rem',
        height: '0.5rem',
        borderRadius: '0',
        transition: 'width 0.5s ease'
    },
    '& .swiper-pagination-bullet-active': {
        width: '1.5rem',
        height: '0.5rem',
        backgroundColor: theme.palette.primary.main
    }
}));
