import { SvgIcon } from '@mui/material';

const DonutItem = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 226 226">
            <path
                d="M0 225.5C0 195.887 5.83273 166.564 17.1652 139.205C28.4976 111.846 45.1078 86.987 66.0474 66.0474C86.987 45.1078 111.846 28.4976 139.205 17.1652C166.564 5.83273 195.887 -1.29443e-06 225.5 0L225.5 67.65C204.771 67.65 184.245 71.7329 165.093 79.6656C145.942 87.5983 128.541 99.2255 113.883 113.883C99.2255 128.541 87.5983 145.942 79.6656 165.093C71.7329 184.245 67.65 204.771 67.65 225.5H0Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default DonutItem;
